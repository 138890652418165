import '../Css/Clints.css';
import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import ClientSlider from './ClientSlider.js';
import { Slide } from 'react-awesome-reveal';

let clients = [
    {
        name: "SARAHM",
        position: "Accountant",
        img_url: "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZ.jpg",
        stars: 3,
        disc: `Moorcroft & Chazbek Premier Immigration truly cares about their clients. Their commitment to excellence and personalized approach sets them apart. I highly recommend their services to anyone seeking immigration assistance.`
    },
    {
        name: " MARIA S",
        position: " Business Owner",
        img_url: "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZRW.jpg",
        stars: 4,
        disc: ` I can’t thank Moorcroft & Chazbek Premier Immigration enough for their professionalism and efficiency. They exceeded my expectations and helped me achieve my immigration goals seamlessly.`
    },
    {
        name: "EMILY T",
        position: " Office Manager",
        img_url: "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZRW.jpg",
        stars: 5,
        disc: ` Moorcroft & Chazbek Premier Immigration has been a game-changer for me. Their expertise and dedication helped me navigate the complex immigration process smoothly. – Emily T.`
    },
    {
        name: "EMILY T",
        position: " Office Manager",
        img_url: "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZRW.jpg",
        stars: 5,
        disc: ` Moorcroft & Chazbek Premier Immigration has been a game-changer for me. Their expertise and dedication helped me navigate the complex immigration process smoothly. – Emily T.`
    },
    {
        name: " MARIA S",
        position: " Business Owner",
        img_url: "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZRW.jpg",
        stars: 4,
        disc: ` I can’t thank Moorcroft & Chazbek Premier Immigration enough for their professionalism and efficiency. They exceeded my expectations and helped me achieve my immigration goals seamlessly.`
    },
    {
        name: "SARAHM",
        position: "Accountant",
        img_url: "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZ.jpg",
        stars: 3,
        disc: `Moorcroft & Chazbek Premier Immigration truly cares about their clients. Their commitment to excellence and personalized approach sets them apart. I highly recommend their services to anyone seeking immigration assistance.`
    },
]
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

const Clints = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i} />
    ))
    return (
        <div className='clints' id='client'>
            <h1>WHAT PEOPLE SAY</h1>
            <div className="border-out">
                <div className="border">
                    <div className="main">

                    </div>
                    <div className="sub">

                    </div>
                </div>
            </div>
            <div className='testimonials'>
                <Slider ref={arrowRef} {...settings}>
                    {clientDisc}
                </Slider>
            </div>
        </div>
    )
}

export default Clints;



