import React, { useState, useEffect } from 'react';
import '../Css/Header.css';
import { MdKeyboardArrowDown } from "react-icons/md";

export default function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [activeMenuItems, setActiveMenuItems] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const toggleSubMenu = (index) => {
    // Toggle the state of the clicked menu item
    setActiveMenuItems(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));

    // Close all submenus except the one clicked
    setOpenSubMenu(openSubMenu === index ? null : index);

    // Deactivate all menu items except the one clicked
    const newActiveMenuItems = {};
    for (let i = 0; i < Object.keys(activeMenuItems).length; i++) {
      if (i === index) {
        newActiveMenuItems[i] = true;
      } else {
        newActiveMenuItems[i] = false;
      }
    }
    setActiveMenuItems(newActiveMenuItems);
  };

  return (
    <>
      <div className="header-top">
        <ul>
          <li className={window.location.pathname === '/' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/'}>
            HOME
          </li>

          <li className={window.location.pathname === '#team' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/'}>
            TEAM
          </li>
          <li className={window.location.pathname === '#team' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/'}>
            RESOURCES
          </li>
          <li className={window.location.pathname === '#team' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/'}>
            CONTACT US
          </li>
        </ul>
      </div>
      <div className={scrolled ? 'header-main scrolled' : 'header-main'}>
        <div className="main">
          <div className="nav">
            <div className="logo">
              <img src="../assets/logo/logo-white.png" alt="" />
            </div>
  
              <div className="logo-scrolled">
                <img src="../assets/logo/logo-color.png" alt="" />
              </div>
          
            <div className="nav-menu">
              <ul>
                <li className={window.location.pathname === '/visit-canada' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/visit-canada'}>
                  VISIT
                </li>
                <li className={window.location.pathname === '/study-in-canada' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/study-in-canada'}>
                  STUDY
                </li>
                <li onClick={() => toggleSubMenu(4)} className={openSubMenu === 4 ? 'active' : ''}>
                  WORK
                  <div className="icon">
                    <MdKeyboardArrowDown className="drop-down" />
                  </div>
                  {openSubMenu === 4 && (
                    <div className="sub-menu">
                      <div className="sub-menu-main">
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/lmia-exempt-work-permit'}>
                            LMIA Work permit
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/start-up-visa-program'}>
                            Open work-permit
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li onClick={() => toggleSubMenu(5)} className={openSubMenu === 5 ? 'active' : ''}>
                  BUSINESS
                  <div className="icon">
                    <MdKeyboardArrowDown className="drop-down" />
                  </div>
                  {openSubMenu === 5 && (
                    <div className="sub-menu">
                      <div className="sub-menu-main">
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/lmia-exempt-work-permit'}>
                            LMIA-Exempt work permit
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/start-up-visa-program'}>
                            Start-up visa
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/entreprenur-or-self-employed-individuals'}>
                            Self-employed or entrepreneurs
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/intra-company-transfer'}>
                            Intracompany transfer
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li onClick={() => toggleSubMenu(6)} className={openSubMenu === 6 ? 'active' : ''}>
                  PERMANENT RESIDENCE
                  <div className="icon">
                    <MdKeyboardArrowDown className="drop-down" />
                  </div>
                  {openSubMenu === 6 && (
                    <div className="sub-menu">
                      <div className="sub-menu-main">

                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/'}>
                            Start-up visa
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/'}>
                            Self-employed
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/express-entry'}>
                            Express Entry
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/provicial-nominee-program'}>
                            Provincial nominee
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/'}>
                            Atlantic Immigration Program
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li onClick={() => toggleSubMenu(7)} className={openSubMenu === 7 ? 'active' : ''}>
                  APPEAL A DECISION
                  <div className="icon">
                    <MdKeyboardArrowDown className="drop-down" />
                  </div>
                  {openSubMenu === 7 && (
                    <div className="sub-menu">
                      <div className="sub-menu-main">

                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/'}>
                            Reconsideration request
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/'}>
                            Judicial review
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/'}>
                            Misrepresentation
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
