import React from 'react'
import '../Css/Pages.css';
export default function SelfEmployedPerson() {
    return (
        <>
            <div className="page-hero study">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a> - Self-employed person-permanent residance</h2>
                                <h1>SELF-EMPLOYED PERSON – PERMANENT RESIDENCE </h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The self-employed permanent residence program is designed for individuals with experience in cultural activities or athletics at a world-class level, or who have been a self-employed person in cultural activities or athletics.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="part study">
                <div className="content">
                    <div className="sub">
                        <h2>REQUIREMENT</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                            In this program, there is a requirement for relevant experience, which the government of Canada defines as a minimum of two (2) years of experience. This experience must be acquired during the period starting five (5) years before the day you apply, and ending on the day the officer makes a decision on your application.
                            <br /><br />
                            In this program, the government of Canada considers the following positions as cultural activities or athletics. However, please note that this list is not exhaustive:
                            <br />
                            <ul>
                                <li>Librarians </li>
                                <li>Archivists </li>
                                <li>Authors and Writers, </li>
                                <li>Editors</li>
                                <li>Journalists,</li>
                                <li>Professional Occupations in Public Relations and Communications</li>
                                <li>Translators</li>
                                <li>Terminologists and Interpreters</li>
                                <li>Producers</li>
                                <li>Directors</li>
                                <li>Choreographers</li>
                                <li>Painters</li>
                                <li>Sculptors and Other Visual Artists</li>
                                <li>Technical and Skilled Occupations in Art</li>
                                <li>Culture</li>
                                <li>Recreation and Sport</li>
                                <li>Library and Public Archive Technicians </li>
                                <li>Photographers</li>
                                <li>Film and Video Camera Operators </li>
                                <li>Graphic Arts Technicians</li>
                                <li>Broadcast Technicians</li>
                                <li>Audio and Video Recording Technicians </li>
                                <li>Announcers and Other Broadcasters</li>
                                <li>Graphic Designers and Illustrators </li>
                                <li>Interior Designers and Interior Decorators</li>
                                <li>Fashion</li>
                                <li>Exhibit and Other Creative Designers</li>
                                <li>Artisans and Craftspersons</li>
                                <li>Patternmakers</li>
                                <li>Textile </li>
                                <li>Leather and Fur Products</li>
                                <li>Athletes, </li>
                                <li>Coaches</li>
                                <li>Sports Officials and Referees </li>
                                <li>Program Leaders and Instructors in Recreation, Sport and Fitness</li>


                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <div className="part left study-left">
                <div className="content">
                    <div className="sub">
                        <h2>SIGNIFICANT CONTRIBUTIONS</h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>
                        <p>
                        When applicants meet the test of relevant experience, and there is a reasonable expectation that they will be self-employed, the test of significant contribution becomes relative.  
<br /><br />
For instance, a music instructor aiming to reside in a sparsely populated rural area would be considered significant on a local scale. Conversely, the situation would differ if they were to establish themselves in a densely populated urban area. Likewise, a freelance journalist who contributes to a Canadian publication would satisfy the criteria.
<br /><br />
Ultimately, the definition of a "significant contribution" is at the discretion of the officer. However, it is not meant to prevent qualified self-employed individuals who are applying in good faith. Its purpose is to deter only frivolous applications.

                        </p>

                    </div>
                </div>
            </div>
            <div className="part study">
                <div className="content">
                    <div className="sub">
                        <h2>FUNDS</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                        Under the self-employed persons program, candidates must demonstrate their intention and ability to create their own employment in Canada. While there is no minimum financial investment required, an officer may use a candidate’s financial assets to measure their intention and ability to establish themselves economically in Canada.
<br /><br />
The capital required will depend on the nature of the work, and a candidate must have enough funds to create an employment opportunity for themselves and support themselves and their family members. This includes the ability to be self-supporting until they have established their business in Canada and the self-employment has been created. 
<br /><br />
In addition, a demonstrated ability to support themselves and their and family through their talents in their country of residence could be a good indicator of their ability to continue to do so in Canada.
</p>
                    </div>
                </div>
            </div>
            <div className="part study">
                <div className="content">
                    <div className="sub">
                        <h2>SELECTION CRITERIA</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                        If an officer is satisfied that an applicant meets the regulatory definition of a self-employed person, they will assess the applicant against the point system for the Self-Employed Persons Class established by Immigration, Refugees and Citizenship Canada. Based on the information and documents provided in the application, candidates are assessed on their education level, their age, their ability in english and their adaptability – which is evaluated by their spouse or common-law partner’s level of education, whether they previously worked or studied in Canada and whether they have relatives in Canada.
                         </p>
                    </div>
                </div>
            </div>
            {/* Our Services */}
            {/* Our Services */}
            {/* Our Services */}
            <div className="page-servies right study">
                <div className="content">
                    <div className="images">

                    </div>
                    <div className="dis">
                        <h2>OUR SERVICES  </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>

                        <p>
                            We guide you through
                        </p>
                        <ul>
                            <li>
                                <strong>Eligibility check :  </strong>: Initially, M&C Premier Immigration conducts a preliminary assessment by asking you a series of questions to determine if you meet the minimum requirements for the Self-Employed Persons program.
                                </li>
                            <li>
                                <strong>Document preparation: </strong>M&C Premier Immigration will assist you in gathering the required documents to substantiate your eligibility.
                                </li>
                            <li>
                                <strong>Timeline of your experience: </strong> Our legal team will create a clear and concise timeline of your experience to present to the visa officer, maximizing your chances of a successful application.
                                </li>
                            <li>
                                <strong>Preparation for any potential interviews : </strong>Interviews may not always be necessary, but an officer might want to learn more about your experience. If this occurs, our legal team will help prepare you for the interview, provide guidance on what to expect, and offer examples of appropriate responses.
</li>
                            <li>
                                <strong>Applying for permanent residence :</strong>M&C Premier Immigration willl care of gathering the required forms and compiling your application for submission to Immigration, Refugees, Citizenship Canada. 
                                </li>
                        </ul>
                        <p>
                            Book a consultation and let’s discuss how you can achieve your academic goals!
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
