import React from 'react';
import '../Css/Servies.css';

export default function Service() {
    return (
        <div className="service">
            <div className="top-border">
            </div>
            <div className="bottom-border">
            </div>
            <div className="main">
                <h1>
                    OUR SERVICES
                </h1>
                <div className="border-out">
                    <div className="border">

                    </div>
                </div>
                <div className="list">

                    {/* Items */}
                    {/* Items */}
                    {/* Items */}

                    <div className="item">
                        <div className="image">
                            <img src="../assets/service/011.jpg" alt="" />
                        </div>
                        <div className="dis">
                            <div className="headiding">
                                <h2>
                                    VISIT CANADA
                                </h2>
                            </div>
                            <div className="details">
                                <p>
                                    Travelling to Canada to visit family, friends or to attend a business opporunity? You may need a visitor visa
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Items */}
                    {/* Items */}
                    {/* Items */}

                    <div className="item">
                        <div className="image">
                            <img src="../assets/service/02.jpg" alt="" />
                        </div>
                        <div className="dis">
                            <div className="headiding">
                                <h2>
                                    STUDY IN CANADA
                                </h2>
                            </div>
                            <div className="details">
                                <p>
                                    A Canadian education offers a world of opportunities. If you want to study in Canada, you will need a study permit.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Items */}
                    {/* Items */}
                    {/* Items */}


                    <div className="item">
                        <div className="image">
                            <img src="../assets/service/03.jpg" alt="" />
                        </div>
                        <div className="dis">
                            <div className="headiding">
                                <h2>
                                    WORK IN CANADA
                                </h2>
                            </div>
                            <div className="details">
                                <p>
                                    Whether you have a job offer from a Canadian employer or wish to explore employment opportunities in Canada, you will need a work permit.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Items */}
                    {/* Items */}
                    {/* Items */}

                    <div className="item">
                        <div className="image">
                            <img src="../assets/service/04.jpg" alt="" />
                        </div>
                        <div className="dis">
                            <div className="headiding">
                                <h2>
                                    BUSINESS IN CANADA
                                </h2>
                            </div>
                            <div className="details">
                                <p>
                                    Do you have a business idea, a start-up or a successful company? Explore opportunities to expand or establish your operations in the country.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Items */}
                    {/* Items */}
                    {/* Items */}

                    <div className="item">
                        <div className="image">
                            <img src="../assets/service/05.jpg" alt="" />
                        </div>
                        <div className="dis">
                            <div className="headiding">
                                <h2>
                                    PERMANENT RESIDENCE
                                </h2>
                            </div>
                            <div className="details">
                                <p>
                                    Do you seek to obtain permanent resdience in Canada? Explore the different pathways for students, workers and entrepreneurs
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Items */}
                    {/* Items */}
                    {/* Items */}

                    <div className="item">
                        <div className="image">
                            <img src="../assets/service/06.jpg" alt="" />
                        </div>
                        <div className="dis">
                            <div className="headiding">
                                <h2>
                                    APPEAL A DECISION
                                </h2>
                            </div>
                            <div className="details">
                                <p>
                                    Has your application been refused by the immigration authorities? You may challenge this decision through judicial review before the Federal Court.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
