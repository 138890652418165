import RoutesFunction from './routes/RoutesFunction.js';
import './App.css';

function App() {
  return (
    <div className="App">
      <RoutesFunction />
    </div>
  );
}

export default App;
