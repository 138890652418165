import React, { useState, useEffect } from 'react';
import '../Css/Book.css';
import { IoMdClose } from "react-icons/io";

export default function Book() {
    const [popup, setPop] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phoneNumber: '',
        message: ''
    });

    useEffect(() => {
        if (popup) {
            document.body.style.overflow = 'hidden'; // Disable scrolling
        } else {
            document.body.style.overflow = 'auto'; // Enable scrolling
        }
    }, [popup]);

    const handleClickOpen = () => {
        setPop(!popup);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Handle form submission here
            console.log(formData);
        }
    }

   

    const validateForm = () => {
        const { name, email, subject, message } = formData;
        if (name === "" || email === "" || subject === "" || message === "") {
            alert("Please fill in all fields.");
            return false;
        }
        return true;
    }

    const closePopup = () => {
        setPop(false);
    }

    return (
        <>
            <div className="book-button">
                <div className="main">
                    <button onClick={handleClickOpen}>
                        BOOK A CONSULTATION
                    </button>
                </div>
            </div>

            {popup &&
                <div className="popup">
                    <div className="main">
                        <div className="bg-images">
                            <img src="../assets/pop/01.png" alt="bg-images" />
                        </div>
                        <div className="form-main">
                            <div className="form-out" onClick={(e) => e.stopPropagation()}>
                                <div className="close" onClick={closePopup}>
                                    <IoMdClose className='close-icon' />
                                </div>
                                <h1>Book A FREE Consultation With Our Expert Advisors Today!</h1>
                                <form id="contactForm" onSubmit={handleSubmit}>

                                    <input type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required />
                                    <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                                    <input type="tel" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} required />
                                    <select name="subject" onChange={handleChange} required>
                                        <option value="option">Book a consultation</option>
                                        <option value="option1">Visit Canada</option>
                                        <option value="option2">Study in Canada</option>
                                        <option value="option3">Work in Canada</option>
                                        <option value="option4">Business in Canada</option>
                                        <option value="option5">Permanent residence </option>
                                        <option value="option6">Misrepresentation</option>
                                        <option value="option7">Request for reconsideration</option>
                                        <option value="option8"> Appeal a decision</option>

                                    </select>
                                    <input type="text" name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required />

                                    <textarea name="message" rows="4" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>

                                    <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
