import React, { useState, useEffect } from 'react';
import '../Css/Hero.css';

export default function Hero() {
    return (
        <>
            <div className="hero">
                <div className="bg-imges">
                    <img src="../assets/images/montreal.jpg" alt="" />
                </div>
                <div className="details">
                    <div className="main">
                        <div className="content">
                            <h1>Montreal | Duba</h1>
                            <hr />
                            <div className="office">
                                <div className="heading">
                                    <h2>LOCATION</h2>
                                </div>
                                <div className="address">
                                    <div className="address-sub">
                                        <h3>
                                            Dubai International District Centre,
                                            <br />Dubai, United Arab Emirates
                                        </h3>

                                    </div>
                                    <div className="address-sub">
                                        <h3>
                                            Greater Montreal Metropolitan Area,<br />
                                            Quebec, Canada
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="contact">
                                <div className="heading">
                                    <h2>CONTACT</h2>
                                </div>
                                <div className="detail">
                                    <div className="phone">
                                        <div className="title">
                                            <h3>
                                                Phone
                                            </h3>

                                        </div>
                                        <div className="content">
                                            <h3>
                                                +971 56 988 6369
                                            </h3>
                                            <h3>
                                                +1(514)-607-6798
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="phone">
                                        <div className="title">
                                            <h3>
                                                Email
                                            </h3>

                                        </div>
                                        <div className="content">
                                            <h3>
                                                info@mcpremierimmigration.com
                                            </h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
