import React from 'react'
import '../Css/Pages.css';

export default function LmiaExemptWorkPermit() {
    return (

        <>
            <div className="page-hero lima">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a> - Study in Canada</h2>
                                <h1>LMIA-EXEMPT WORK PERMIT</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                A labour market test will not be required for work permits which are LMIA-exempt. These type of work permits will be issued under various business immigration streams to foreign workers when their work can result in significant economic, social or cultural benefits to Canadians.
                                <br /><br />
                                The Canadian immigration landscape provides for various LMIA-exempt work permits designed to facilitate the admission of those seeking to establish or expand their ventures in Canada and contribute to the country’s economic growth.
                                <br /><br />
                                These pathways offer opportunities for talented individuals to bring their business ideas to fruition. Each program offers a unique set of requirements and benefits, catering to different profiles of entrepreneurs and business owners.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Category */}
            {/* Category */}
            {/* Category */}

            <div className="page-category">
                <div className="main">
                    <h2>Which of these key programs would be best suited to help make Canada your new home? </h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <div className="list">
                        <a href="/start-up-visa-program" className="item" >
                            <div className="image">
                                <div className="img">

                                </div>

                            </div>
                            <div className="dis">
                                <p>
                                    <strong>START-UP VISA (C10)</strong>  is a temporary work permit under the International Mobility Program issued to foreign professionals who present a significant social, cultural, or economic benefit to Canada by starting a business that creates jobs, brings new technologies to Canada, contributes to Canada’s cultural landscape or causes to significant advancements in their industry.
                                </p>
                            </div>
                        </a>
                        <a href="/entreprenur-or-self-employed-individuals" className="item" >
                            <div className="image">
                                <div className="img">

                                </div>

                            </div>
                            <div className="dis">
                                <p>
                                    <strong>ENTREPRENEUR OR SELF-EMPLOYED INDIVIDUALS (C11) </strong> is a temporary work permit visa under the International Mobility Program issued to foreign entrepreneurs and investors who intend to work as self-employed individuals who can run a business in Canada.
                                </p>
                            </div>
                        </a>
                        <a href="/intra-company-transfer" className="item" >
                            <div className="image">
                                <div className="img">

                                </div>

                            </div>
                            <div className="dis">
                                <p>
                                    <strong>INTRA-COMPANY TRANSFER (C12) (C10)</strong>  is temporary work permit under the International Mobility Program that allows qualified foreign business owners to transfer their businesses to Canada and acquire a work permit.
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}
