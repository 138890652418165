import React from 'react';
import Mission from "../Components/Mission.js";
import Hero from "../Components/Hero.js";
import Form from "../Components/Form.js";
import WhoWe from "../Components/WhoWe.js";
import Expert from "../Components/Expert.js";
import Service from "../Components/Service.js";
import Clints from "../Components/Clints.js";
import Tabs from '../Components/Tabs.js';

export default function Home() {
  return (
    <>
      <Hero />
      {/* <Form/> */}
      <WhoWe />
      <Mission />
      <Expert />
      <Service/>
      <Clints/>
      {/* <Tabs/> */}
    </>
  )
}
