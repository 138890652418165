import React, { useState } from 'react'
import '../Css/Pages.css';
export default function EntreOrSelfemployedIndividuals() {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const tabs = [
        {
            label: 'SELF-EMPLOYED   ',
            heading: ' SELF-EMPLOYED  ',
            content:
                <p>
                    SELF-EMPLOYED A person who works for themselves as the owner of a business and rarely hires people outside of their family members. While many individuals are the owners of the business, they typically carry out all the responsibilities of an employee.
                </p>,
        },
        {
            label: 'ENTREPRENEUR  ',
            heading: 'ENTREPRENEUR ',
            content:
                <p>
                    ENTREPRENEUR A person who organizes and operates a business or businesses, taking on greater than normal financial risks to do so. They hire employees other than their family members to carry out the activities of the business
                </p>
        },
    ];
    return (

        <>
            <div className="page-hero enter">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a> - entrepreneur or self-emploed individuals</h2>
                                <h1>ENTREPRENEUR OR SELF-EMPLOYED INDIVIDUALS (C11)</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The Entrepreneur or Self-Employed Individuals work permit is designed for individuals aspiring to establish or manage a business in Canada.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Tabs */}
            {/* Tabs */}
            {/* Tabs */}


            <div className="page-tabs enter">
                <div className='main'>
                    <h2 className='heading'>
                        The Government of Canada defines candidates for this work permit as follows :

                    </h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>

                    <div className="tab-header">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`tab-item ${activeTab === index ? 'active' : ''}`}
                                onClick={() => handleTabClick(index)}
                            >
                                <h2>{tab.label}</h2>
                            </div>
                        ))}
                    </div>
                    <div className="tab-content">
                        <h2>{tabs[activeTab].heading}</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        {tabs[activeTab].content}
                    </div>
                </div>
            </div>


            {/* Start Normal */}
            {/* Start Normal */}
            {/* Start Normal */}
            <div className="page-start">
                <div className="main">
                    <h2>GENERAL REQUIREMENTS</h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        What distinguishes this program from other LMIA-exempt work permits is that applicants must demonstrate that they have skills, background and experience to set up a successful business in Canada, or take over an existing business and manage it successfully.
                        <br />
                        <br />
                        In addition, candidates must :
                        <ul>
                            <li>
                                establish themselves in Canada
                            </li>
                            <li>
                                demonstrate ownership of at least <strong>fifty (50) percent of shares in their business</strong>
                            </li>
                            <li>
                                issue themselves an offer of employment respecting the requirements set forth by Immigration, Refugees, and Citizenship Canada
                            </li>
                            <li>
                                establish that their business activity will not be detrimental to the interests of Canadian service providers.
                            </li>
                            <li>
                                demonstrate the temporary nature of their stay
                            </li>
                            <li>
                                prove that their business activities in Canada will result in <strong>economic, social or cultural benefits or opportunities</strong> for Canadian citizens or permanent residents
                            </li>
                        </ul>
                        <br />
                        Apart from the challenges associated with establishing a start-up or taking over an existing business in Canada, foreign entrepreneurs must very carefully consider the requirement of demonstrating significant benefits of their business, often regarded as the most strategic aspect of the application process.
                    </p>
                </div>
            </div>

            {/* Start Normal */}
            {/* Start Normal */}
            {/* Start Normal */}

            <div className="page-start-bg enter">
                <div className="main">
                    <h2>SPIN OFF BENEFITS </h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        Under this program, officers have the authority to evaluate any additional benefit from self-employment when making their decision. At M&C Premier Immigration, we make sure to address all these elements when crafting the business plan for your work permit application – including but is not limited to:
                        <br />
                        <br />
                        <ul>
                            <li>
                                is the business likely to create jobs in the area?
                            </li>
                            <li>
                                is the business likely to provide economic stimulus to the area?
                            </li>
                            <li>
                                does the applicant have the language abilities to operate the business?
                            </li>
                            <li>
                                does the applicant have a particular background or skills that will improve the viability of the business?
                            </li>
                            <li>
                                has the applicant taken steps to initiate their business?
                            </li>
                            <li>
                                has the applicant put the business plan into action?
                            </li>
                            <li>
                                does the applicant have the financial ability to begin the business?
                            </li>
                            <li>
                                does the applicant have the financial ability to pay expenditure?
                            </li>
                            <li>
                                can the applicant rent a space?
                            </li>
                            <li>
                                does the applicant have a realistic staffing plan?
                            </li>
                            <li>
                                does the applicant have a business number?
                            </li>
                            <li>
                                does the applicant have ownership documents or agreements?
                            </li>
                            <li>
                                is the business of a temporary nature?
                            </li>
                            <li>
                                is the foreign national establishing a long-term business that will require their presence indeterminately?
                            </li>
                        </ul>
                        <br />
                        The success of this work permit depends on the effective presentation of your venture, and the demonstration that all requirements of the program have been fulfilled. The business plan must outline the significant benefits of the business, as defined in immigration regulations, and provide sufficient information of your background and skills as a self-employed person or entrepreneur.
                    </p>
                </div>
            </div>

            {/* Part */}
            {/* Part */}
            {/* Part */}



            <div className="part enter ">
                <div className="content">
                    <div className="sub">
                        <h2>FUNDS</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                            The guidelines of this work permit do not provide for a minimum financial requirements. However, candidates must be able to demonstrate financial capacity to begin operations in Canada. The amount of funds presented will depend on your business plan, initial capital investment and financial projections. We suggest that you have sufficient financial resources to invest in your venture for the first two (2) years before your business starts generating solid returns.
                            <br /><br />
                            If your budget is between 250,000 and 500,000 CAD and you can tolerate a moderate amount of risk, we suggest that you explore buying a franchise or a non-franchised business.

                        </p>
                    </div>
                </div>
            </div>
            <div className="part enter left">
                <div className="content">
                    <div className="sub">
                        <h2>DURATION</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                            The government of Canada offers pathways for international students to become permanent residents if they want to remain in the country. The Post-Graduate Work Permit (PGWP) allows international students to remain and work in Canada for up to three (3) years after graduation and is usually granted for the same duration as your studies in Canada. After working in Canada for twelve (12) months, international graduates may be eligible to apply for permanent residency.

                        </p>
                        <br />
                        <h2>PERMANENT RESIDENCE PATHWAY</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                            After one year of work experience, candidates become eligible for an Express Entry application for permanent residence. Once you become a permanent resident, you can continue working as an employee or a business owner.
                        </p>
                    </div>
                </div>
            </div>
            {/* Our Services */}
            {/* Our Services */}
            {/* Our Services */}
            <div className="page-servies right enter">
                <div className="content">
                    <div className="images">

                    </div>
                    <div className="dis">
                        <h2>OUR SERVICES  </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>

                        <p>
                            This program is not a one-size-fits-all deal; and candidates must make strategic decisions in the establishment of their business to convince the officer of their intention to begin operations in Canada – all while minimizing expenditures. That's why having an expert lawyer by your side is crucial.
                            <br /><br />
                            M&C Premier Immigration provides the following services to support individuals applying for the Entrepreneur or Self-Employed Individuals work permit:


                            <ul>
                                <li>
                                    <strong>Building a business plan:  </strong>Our expert lawyers work closely with you to craft a detailed business plan that highlights the unique advantages of your venture, ensuring alignment with immigration requirements and business objectives.
                                </li>
                                <li>
                                    <strong>Conducting market research:  </strong>M&C Premier Immigration will assist you with conducting thorough market research to identify opportunities, assess competition, and understand consumer preferences, providing valuable insights to inform your business strategy and immigration application.
                                </li>
                                <li>
                                    <strong>Developing an execution plan: </strong>Our lawyers assist in developing a comprehensive execution plan that outlines actionable steps to achieve your business goals, ensuring clarity, feasibility, and compliance with immigration regulations.
                                </li>
                                <li>
                                    <strong>Assisting you in establishing your business in Canada: </strong> Our lawyers provide comprehensive legal support to navigate the complexities of establishing your business in Canada, including incorporation, licensing, permits, and compliance with regulatory frameworks.
                                </li>
                                <li>
                                    <strong>Making strategic decisions to launch your business:  </strong>Leveraging our legal and business expertise, we offer strategic guidance to help you make informed decisions at every stage of launching your business in Canada, ensuring optimal outcomes for both your business and immigration goals.
                                </li>
                                <li>
                                    <strong>Drafting a job offer in compliance with the guidelines: </strong>Our experienced lawyers draft precise and legally compliant job offers tailored to meet immigration requirements, ensuring clarity, accuracy, and alignment with Canadian labor laws.
                                </li>
                                <li>
                                    <strong>Applying for your work permit: </strong>We guide you through the process of applying for a work permit, providing support to compile required documentation, navigate application procedures, and address any legal complexities to facilitate a successful application.
                                </li>
                            </ul>
                            Book and consultation with us to find out if you’re eligible!
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
