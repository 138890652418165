import React, { useState } from 'react';
import '../Css/Pages.css';

export default function StudyInCanada() {

    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    const tabs = [
        {
            label: 'STEP 1 ',
            heading: ' LETTER OF ACCEPTANCE',
            content:
                <p>
                    To become an international student, a foreign national must secure an letter of acceptance from a designated learning institution (DLI) before applying for a study permit. Once you obtain a letter of acceptance, the institution will send a Provincial Attestation Letter (PLA) to Immigration, Refugees and Citizenship Canada on your behalf.
                    <br />
                    <br />
                    Make sure you’re aware of the proper deadline for your program, as Canadian institutions tend to impose different application deadlines for international students than for students applying from inside of Canada. As the processing times for a study permit may vary from one country to another, we also recommend students to plan ahead, to ensure that your application will be processed before your first day of class.

                </p>,
        },
        {
            label: 'STEP 2 ',
            heading: ' APPLY FOR A STUDY PERMIT',
            content: <p>
                A study permit does not allow a foreign national to enter Canada, and a temporary resident visa will generally be issued with the study permit. For this reason, applicants are required to demonstrate their intention to leave the country at the end of their authorized stay by convincing the authorities of their genuine intention to study in Canada.
                <br />
                <br />
                At M&C Premier Immigration, our lawyers are well equipped to help you find the right institution and offer guidance on applying to your program of choice. Beyond obtaining the documentation required by immigration authorities, our team will assist you in building a study plan that is coherent with your academic and professional goals. The study plan will increase chances of a successful application and avoid the following common grounds for refusal:
                <ul>
                    <li>
                        The applicant's previous studies were in an unrelated field.
                    </li>
                    <li>
                        The applicant's previous employment and educational history demonstrate an inconsistent career progression.
                    </li>
                    <li>
                        The applicant has previous studies at the same academic level as the proposed studies in Canada.
                    </li>
                </ul>
            </p>
        },
    ];




    return (

        <>
            {/* Hero */}
            {/* Hero */}
            {/* Hero */}
            <div className="page-hero study">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a> - Study in Canada</h2>
                                <h1>STUDY IN CANADA </h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                Canada is a top destination for international students from all over the world. From university to post-graduate studies, Canada is known for offering high quality education, internship opportunities and access to a wide network of professionals. Whether you decide to stay in Canada or return to your country of residence, your academic experience and acquired connections will propel your career. If you want to benefit from Canadian education, you will need a study permit.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            {/* Tabs */}
            {/* Tabs */}
            {/* Tabs */}
            <div className="page-tabs study">
                <div className='main'>
                    <h2 className='heading'>TWO-STEP PROCESS</h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <div className="tab-header">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`tab-item ${activeTab === index ? 'active' : ''}`}
                                onClick={() => handleTabClick(index)}
                            >
                                <h2>{tab.label}</h2>
                            </div>
                        ))}
                    </div>
                    <div className="tab-content">
                        <h2>{tabs[activeTab].heading}</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        {tabs[activeTab].content}
                    </div>
                </div>

            </div>



            {/* Part */}
            {/* Part */}
            {/* Part */}
            <div className="part study">
                <div className="content">
                    <div className="sub">
                        <h2>AFTER GRADUATION</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                            The government of Canada offers pathways for international students to become permanent residents if they want to remain in the country. The Post-Graduate Work Permit (PGWP) allows international students to remain and work in Canada for up to three (3) years after graduation and is usually granted for the same duration as your studies in Canada. After working in Canada for twelve (12) months, international graduates may be eligible to apply for permanent residency.
                        </p>
                    </div>
                </div>
            </div>
            {/* Our Services */}
            {/* Our Services */}
            {/* Our Services */}
            <div className="page-servies study">
                <div className="content">
                    <div className="images">

                    </div>
                    <div className="dis">
                        <h2>OUR SERVICES  </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>

                        <p>
                            We guide you through
                            <ul>
                                <li>
                                    <strong>Exploring Canadian academic institutions : </strong>Canada is home to a wide range of internationally renowned academic institutions, each having their own culture and community. At M&C Premier Immigration, our team will refer you to the top choices of academic institutions tailored to your individual preferences, including location within Canada and your preferred program of study.
                                </li>
                                <li>
                                    <strong>Writing a letter of intent : </strong>To secure acceptance from Canadian university in competitive programs and stand out as a candidate, a letter of intent is of paramount importance. We provide assistance in drafting a letter that will highlight your qualities and competences, to paint an accurate picture of your candidacy.
                                </li>
                                <li>
                                    <strong>Building your curriculum vitae (CV):</strong> M&C Premier Immigration assists their clients in crafting a curriculum vitae that emphasizes their accomplishments and presents their employment history in a clear and organized manner, ensuring relevance to your program of study and letter of intent.
                                </li>
                                <li>
                                    <strong>Developing your study plan : </strong> It's crucial to include a study plan in your application. Our team will work with you to outline why you want to study in your accepted Canadian program, why you're not choosing similar programs in your home country, your academic and career goals, and how this program will benefit your opportunities back home. We'll also discuss how your program choice aligns with your previous academic and work experiences.
                                </li>
                                <li>
                                    <strong>Gathering required documentation : </strong>M&C Premier Immigration will care of gathering the required forms and compiling your application for submission to Immigration, Refugees, Citizenship Canada.
                                </li>
                            </ul>
                            Book a consultation and let’s discuss how you can achieve your academic goals!
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
