import React, { useState } from 'react'
import '../Css/Pages.css';
export default function ExpressEntry() {

   

    return (


        <>
            <div className="page-hero express">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a> - express entry</h2>
                                <h1>EXPRESS ENTRY</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                There are three immigration programs managed through Express Entry. These programs vary in their minimum requirements for eligibility, despite their general similarity. The three distinct programs and their minimal prerequisites are listed below:
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            {/* Category */}
            {/* Category */}
            {/* Category */}

            <div className="page-category">
                <div className="main">
                    <div className="list">
                        <div className="item" >
                            <div className="image">
                                <div className="img">

                                </div>

                            </div>
                            <div className="dis">
                                <p>
                                    Canadian Experience Class The Canadian Experience Class is for skilled workers who have Canadian work experience seeking permanent residency. (Canadian skilled work experience, Language proficiency)
                                </p>
                            </div>
                        </div>
                        <div className="item" >
                            <div className="image">
                                <div className="img">

                                </div>

                            </div>
                            <div className="dis">
                                <p>
                                    Federal Skilled Worker Program The Federal Skilled Worker Program is for skilled workers who have work experience seeking permanent residency (Skilled work experience, Language proficiency, Education).
                                </p>
                            </div>
                        </div>
                        <div className="item" >
                            <div className="image">
                                <div className="img">

                                </div>

                            </div>
                            <div className="dis">
                                <p>
                                    Federal Skilled Trade Program The Federal Skilled Trades Program is for skilled workers seeking permanent residency based on being qualified in a skilled trade. (Skilled work experience, Job offer or certificate of qualification, Language proficiency).
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Start Normal bg */}
            {/* Start Normal bg */}
            {/* Start Normal bg */}

            <div className="page-start-bg express">
                <div className="main">
                    <h2>
                        CANADIAN EXPERIENCE CLASS
                    </h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        <strong>
                            REQUIREMENTS
                        </strong>
                        <br />
                        To be eligible for the Canadian Experience Class, candidates must satisfy all the minimum requirements for Canadian skilled work experience and language ability.
                    </p>
                    <br />
                    <p>
                        <strong>
                            EXPERIENCE
                        </strong>
                        <br />
                        A foreign national must have at least one year of skilled work experience in Canada within the three (3) years preceding your application, or an equivalent amount of part-time work experience. This experience must have been gained while you were authorized to work under temporary resident status in Canada. Your responsibilities and tasks should align with those listed in the lead statement of the occupational description in the National Occupational Classification (NOC), covering all essential duties and most main duties. Skilled work experience falls under the NOC Training, Education, Experience, and Responsibilities (TEER) categories 0, 1, 2, or 3. The work must be paid, excluding volunteer work or unpaid internships. self-employment and work experience acquired as a full-time student, including co-op work terms.
                    </p>
                    <br />
                    <p>
                        <strong>
                            LANGUAGE ABILITIES
                        </strong>
                        <br />
                        You must take approved language tests for writing, reading, listening, and speaking, meeting the minimum score required. These test results are valid for two years from the date of the test and must be valid on the day you apply for permanent residence.
                    </p>
                    <br />
                    <p>
                        <strong>
                            EDUCATION
                        </strong>
                        <br />
                        There is no specific education requirement for the Canadian Experience Class. However, you can improve your ranking in the Express Entry pool by obtaining points for educational credentials obtained in Canada or through an educational credential assessment report for immigration purposes from a designated organization, demonstrating equivalence to Canadian secondary or post-secondary education.
                    </p>
                </div>
            </div>

            {/* Start Normal */}
            {/* Start Normal */}
            {/* Start Normal */}
            <div className="page-start">
                <div className="main">
                    <h2>FEDERAL SKILLED WORKER PROGRAM</h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        <strong>
                            REQUIREMENTS
                        </strong>
                        <br />
                        Under this program, candidates must satisfy all the minimum requirements in three key areas: skilled work experience, language ability, and education. Once these minimum requirements are met, the IRCC will evaluate your application based on additional selection factors.
                    </p>
                    <br />
                    <p>
                        <strong>
                            SKILLED WORK EXPERIENCE
                        </strong>
                        <br />
                        Skilled work experience involves working in one of the following National Occupational Classification (NOC) TEER categories: TEER 0, TEER 1, TEER 2, or TEER 3. You need to demonstrate that while employed in your primary occupation, you performed duties outlined in the lead statement of the occupational description in the NOC, encompassing all essential duties and most main duties listed.What distinguishes this program from other LMIA-exempt work permits is that applicants must demonstrate that they have skills, background and experience to set up a successful business in Canada, or take over an existing business and manage it successfully.
                        <br />
                        <br />
                        Your skilled work experience must:
                        <ul>
                            <li>
                                Be in the same type of job (have the same NOC) as the one you're applying for immigration (referred to as your primary occupation).
                            </li>
                            <li>
                                Have been acquired within the past ten (10) years, either in Canada or abroad.
                            </li>
                            <li>
                                Involve paid work, with wages or commission earned (volunteer work or unpaid internships are not considered).
                            </li>
                            <li>
                                Amount to at least one year of continuous work or 1,560 hours in total (equivalent to 30 hours per week).
                            </li>
                        </ul>
                        <br />
                        You can meet this requirement through :
                        <ul>
                            <li>
                                Full-time employment at one job for thirty (30) hours per week for twelve (12) months (1,560 hours).
                            </li>
                            <li>
                                An equivalent amount of part-time work, such as fifteen (15) hours per week for twenty-four (24) months.
                            </li>
                            <li>
                                Working multiple part-time jobs to accumulate the required hours.
                            </li>
                        </ul>
                        <br />
                        For part-time work, you can work more or less than fifteen (15) hours per week, as long as the total hours reach 1,560. Hours worked beyond thirty (30) per week are not counted.
                    </p>
                    <br />
                    <p>
                        <strong>
                            STUDENT WORK EXPERIENCE
                        </strong>
                        <br />
                        Work experience gained during your studies may count towards the minimum requirements if it meets specific criteria: paid by wages or commissions, continuous without employment gaps, and meets all other program requirements.
                    </p>
                    <br />
                    <p>
                        <strong>
                            LANGUAGE ABILITIES
                        </strong>
                        <br />
                        You must take approved language tests in English or French for writing, reading, listening, and speaking, meeting the minimum score required in all four abilities. Test results are valid for two years and must be valid on the day you apply for permanent residence (CELPIP, IELTS, PTE).
                    </p>
                    <br />
                    <p>
                        <strong>
                            EDUCATION
                        </strong>
                        <br />
                        If you attended school in Canada, you must possess a certificate, diploma, or degree from a Canadian secondary or post-secondary institution. For foreign education, you must hold a completed educational credential and an Educational Credential Assessment for immigration purposes from a designated organization, demonstrating equivalence to a Canadian certificate, diploma, or degree.
                    </p>
                    <br />
                    <p>
                        <strong>
                            SELECTION FACTORS
                        </strong>
                        <br />
                        If you meet all minimum requirements, Immigration, Refugees and Citizenship Canada will assess your eligibility for the Federal Skilled Worker Program based on six (6) factors: age, education, work experience, valid job offer, language skills, and adaptability. An overall score out of hundred (100) will be assigned, with a current pass mark of sixty-seven (67) points. These points are different from the ones used by Immigration, Refugees and Citizenship Canada to assess and rank your profile in the Express Entry pool.
                    </p>
                    <br />
                    <p>
                        <strong>
                            FUNDS
                        </strong>
                        <br />
                        You must demonstrate sufficient funds for you and your family to settle in Canada, unless you are currently authorized to work in Canada or have a valid job offer from a Canadian employer. The amount may vary between $13,757 CAD to $36,407 CAD, depending on the number of family members.
                    </p>
                </div>
            </div>


            {/* Start Normal bg2 */}
            {/* Start Normal bg2 */}
            {/* Start Normal bg2 */}

            <div className="page-start-bg express2">
                <div className="main">
                    <h2>
                        FEDERAL SKILLED TRADE PROGRAM
                    </h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        <strong>
                            REQUIREMENTS
                        </strong>
                        <br />
                        To qualify for the program, you must meet all the minimum requirements related to your skilled trades work experience, job offer or certificate of qualification, and language ability.
                    </p>
                    <br />
                    <p>
                        <strong>
                            SKILLED TRADE WORK EXPERIENCE
                        </strong>
                        <br />
                        You need to have a minimum of two years of full-time work experience (or equivalent part-time experience) in a skilled trade within the five years preceding your application, and meet the job requirements set out in the National Occupational Classification (NOC), demonstrating your ability to perform all essential duties and most main duties listed. In addition, your work experience must be paid (wages or commission), and volunteer work or unpaid internships are not considered. You must be qualified to independently practice the trade occupation during your work.
                        <ul>
                            <li>
                                Major Group 72: Technical trades and transportation officers and controllers (excluding Sub-Major Group 726)
                            </li>
                            <li>
                                Major Group 73: General trades
                            </li>
                            <li>
                                Major Group 82: Supervisors in natural resources, agriculture, and related production
                            </li>
                            <li>
                                Major Group 83: Occupations in natural resources and related production
                            </li>
                            <li>
                                Major Group 92: Processing, manufacturing, and utilities supervisors, and utilities operators and controllers
                            </li>
                            <li>
                                Major Group 93: Central control and process operators and aircraft assembly assemblers and inspectors (excluding Sub-Major Group 932)
                            </li>
                            <li>
                                Minor Group 6320: Cooks, butchers, and bakers
                            </li>
                            <li>
                                Unit Group 62200: Chefs
                            </li>
                        </ul>
                    </p>
                    <p>
                        <strong>
                            JOB OFFER OR CERTIFICATION OF QUALIFICATION
                        </strong>
                        <br />
                        You must possess a valid job offer for full-time employment lasting at least one year, or a certificate of qualification in your skilled trade issued by a Canadian provincial, territorial, or federal authority. This certificate confirms your eligibility to work in a specific skilled trade in Canada. To obtain it, you must pass a certification exam and fulfill all requirements to practice your trade in the issuing province or territory.
                    </p>
                    <br />
                    <p>
                        <strong>
                            LANGUAGE ABILITY
                        </strong>
                        <br />
                        To prove that you have the required language proficiency, you must take approved language tests (CELPIP, IELTS, PTE) in writing, reading, listening, and speaking and meet the minimum score requirements in all four abilities. Once you obtain your results, you need to enter the test results in your Express Entry profile. Note that all language test results are valid for two years from the test date and must be valid on the day you apply for permanent residence.
                    </p>
                    <br />
                    <p>
                        <strong>
                            EDUCATION
                        </strong>
                        <br />
                        There is no specific education requirement for the Federal Skilled Trades Program. However, you can earn additional points in the Express Entry pool if you hold educational credentials from Canadian or designated foreign institutions.
                    </p>
                    <br />
                    <p>
                        <strong>
                            FUNDS
                        </strong>
                        <br />
                        You must demonstrate sufficient funds to settle in Canada unless you are currently authorized to work in Canada and have a valid job offer from a Canadian employer.
                    </p>
                </div>
            </div>

            {/* Our Services */}
            {/* Our Services */}
            {/* Our Services */}
            <div className="page-servies right express">
                <div className="content">
                    <div className="images">

                    </div>
                    <div className="dis">
                        <h2>OUR SERVICES  </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>

                        <p>
                            The process of verifying your eligibility and building an application is detail-oriented, and can be time-consuming. To ensure accuracy in evaluating your profile and to prevent any delays, extra expenses, or application rejection, it's advisable to hire immigration lawyers to handle your application preparation, maximizing your chances of success.

                            <ul>
                                <li>
                                    <strong>Eligibility Check:  </strong>Initially, M&C Premier Immigration conducts a preliminary assessment by asking you a series of questions to determine if you meet the minimum requirements for each program within Express Entry.
                                </li>
                                <li>
                                    <strong>Score Evaluation:  </strong>Upon confirming your eligibility for one or more Express Entry programs, we proceed to evaluate your score using the Comprehensive Ranking System (CRS).
                                </li>
                                <li>
                                    <strong>Document Preparation:</strong> M&C Premier Immigration will assist you in gathering essential documents required to substantiate your eligibility.
                                </li>
                                <li>
                                    <strong>Profile Completion: </strong> M&C Premier Immigration will create your Express Entry profile, ensuring that all necessary information is accurately provided. If deemed eligible, Immigration, Refugees, and Citizenship Canada will accept you into the candidate pool and assign you a CRS score.
                                </li>
                                <li>
                                    <strong>Invitation and Application Submission: </strong>If Immigration, Refugees, and Citizenship Canada identifies you as one of the top-scoring candidates in the pool, you will receive an invitation to apply. If invited, our team submits your application for permanent residence under Express Entry within the sixty (60) day deadline.
                                </li>
                            </ul>
                            We handle all the paperwork and ensure that all necessary documents are uploaded accurately to prevent any risks of rejection. Typically, applications with all required documents undergo processing within six months
                        </p>
                    </div>
                </div>
            </div>


            


           
        </>
    )
}
