import React from 'react'
import '../Css/Pages.css';
export default function ReconsideraRequest() {
    return (

        <>
            <div className="page-hero study">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a> - Reconsidera request </h2>
                                <h1>RECONSIDERATION REQUEST</h1>
                            </div>
                        </div>
                        <div className="introduce">

                            <p>
                                Canada is a top destination for international students from all over the world. From university to post-graduate studies, Canada is known for offering high quality education, internship opportunities and access to a wide network of professionals. Whether you decide to stay in Canada or return to your country of residence, your academic experience and acquired connections will propel your career. If you want to benefit from Canadian education, you will need a study permit.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="part study">
                <div className="content">
                    <div className="sub">
                        <h2>WHAT IS A RECONSIDERATION REQUEST?</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                            A reconsideration request is an informal process through which an applicant may request a visa officer to reconsider a previously rejected immigration application. In the reconsideration request, the applicant must explain why they think the visa officer made a mistake.
                            <br /><br />
                            The main advantage of a reconsideration request is that it can be submitted via webform through a case specific inquiry to Immigration, Refugees and Citizenship Canada, making it a straightforward option compared to other legal avenues. It's important to note, however, that visa officers hold a significant degree of discretion when assessing reconsideration requests. The likelihood of success depends on the extent of the error in the motives for refusal of your application.

                        </p>
                    </div>
                </div>
            </div>
            <div className="part left study-left">
                <div className="content">
                    <div className="sub">
                        <h2>CONTENT OF THE REQUEST  </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>
                        <p>
                            In building a reconsideration request, it is crucial to :
                            <ul>
                                <li>
                                    clearly state your desired outcome
                                </li>
                                <li>
                                    identify and address any factual or legal error made by the officer
                                </li>
                                <li>
                                    present additional evidence if new facts have emerged since your initial application
                                </li>
                                <li>
                                    explain how these new developments are relevant to your case and why they warrant a reconsideration.
                                </li>
                            </ul>
                        </p>
                        <p>
                            Lastly, if there are compelling reasons beyond the applicant’s control that contributed to the refusal, such as the death of a close family member, third-party delays, or natural disasters, they need to provide evidence to support these claims, and explain how these exceptional circumstances impacted their immigration application.
                            <br /><br />
                            One must remember that the visa officer will review your request and decide whether to reopen your file. A well-written, polite and respectful request can increase the likelihood of a favorable decision.

                        </p>

                    </div>
                </div>
            </div>

            {/* Our Services */}
            {/* Our Services */}
            {/* Our Services */}
            <div className="page-servies right study">
                <div className="content">
                    <div className="images">

                    </div>
                    <div className="dis">
                        <h2>OUR SERVICES  </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>

                        <p>
                        A reconsideration will only be accepted when the circumstances are justified. At M&C Premier Immigration, our lawyers can help you identify errors and advocate for your application to be reconsidered and accepted. The legal fees of reconsideration requests are dependent on the complexity of the case.
                        </p>
                       
                        <p>
                        Book a consultation and find out whether a reconsideration request is the right avenue for your refusal.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
