import React, { useState } from 'react'
import '../Css/Pages.css';

export default function WorkInCanada() {

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    const tabs = [
        {
            label: 'CLOSED WORK PERMITS',
            heading: ' CLOSED WORK PERMITS',
            content:
                <p>
                    <strong>CLOSED WORK PERMITS</strong> Canadian work permits are employer specific, otherwise referred to as Closed Work Permits. They are granted to foreign workers for a specific job in Canada. You can only work for the employer specified on the document. If you find another job and you do not yet have permanent resident status, you must apply for and receive a new work permit prior to changing employers in Canada.
                </p>,
        },
        {
            label: 'OPEN WORK PERMITS  ',
            heading: ' OPEN WORK PERMITS ',
            content:
                <p>
                    <strong>OPEN WORK PERMITS</strong> In limited situations, Canadian immigration regulations allow for Open Work Permits, which are not specific to an employer.
                </p>
        },
    ];
    const tabs2 = [
        {
            label: 'LMIA',
            heading: ' LMIA',
            content:
                <p>
                    An <strong>LMIA</strong> Work Permit requires applicants to provide a Labour Market Impact Assessment (LMIA) with their work permit application. The LMIA demonstrates that the issuance of a work permit to a foreign national will not have a negative impact on the employment and salaries of Canadian workers. This test is conducted by Employment and Social Development Canada (ESDC).
                </p>,
        },
        {
            label: 'LMIA-exempt',
            heading: ' LMIA-exempt',
            content:
                <p>
                    An LMIA Work Permit requires applicants to provide a Labour Market Impact Assessment (LMIA) with their work permit application. The LMIA demonstrates that the issuance of a work permit to a foreign national will not have a negative impact on the employment and salaries of Canadian workers. This test is conducted by Employment and Social Development Canada (ESDC). A positive or neutral LMIA is issued by ESDC when it is satisfied that there is no Canadian citizen or permanent resident available to do the job. A negative LMIA will be issued when it believes that hiring a foreign national will harm domestic workers in Canada.

                </p>
        },
    ];

    return (
        <>



            {/* Hero */}
            {/* Hero */}
            {/* Hero */}
            <div className="page-hero work">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a> - Work in Canada</h2>
                                <h1>WORK IN CANADA</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                If you want to work in Canada – whether it be with a Canadian employer or to establish yourself as an entrepreneur – you will need a work permit. Navigating Canada's work permit options can be confusing, and M&C Premier Immigration is committed to helping you find the program that suits you best, considering your professional background and business ventures.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            {/* Tabs */}
            {/* Tabs */}
            {/* Tabs */}
            <div className="page-tabs work">
                <div className='main'>
                    <h2 className='heading'>OPEN WORK PERMIT</h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        In the context of Canadian immigration, “work” is broadly defined as an activity for which wages are paid or commission is earned.
                    </p>
                    <div className="tab-header">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`tab-item ${activeTab === index ? 'active' : ''}`}
                                onClick={() => handleTabClick(index)}
                            >
                                <h2>{tab.label}</h2>
                            </div>
                        ))}
                    </div>
                    <div className="tab-content">
                        <h2>{tabs[activeTab].heading}</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        {tabs[activeTab].content}
                    </div>
                    <p>
                        A work permit will always be temporary in nature but can be extended from within Canada.
                    </p>
                </div>

            </div>
            {/* Part */}
            {/* Part */}
            {/* Part */}
            <div className="part work">
                <div className="content">
                    <div className="sub">
                        <h2>ELIGIBILITY</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                            Who can obtain an open work permit?
                            <ul>
                                <li>
                                    International students with a valid study permit who have graduated from a Canadian post-secondary institution.
                                </li>
                                <li>
                                    Spouses or common-law partners of Canadian citizens or permanent residents.
                                </li>
                                <li>
                                    Dependent children of Canadian citizens or permanent residents.
                                </li>
                                <li>
                                    Other limited situations determined by Immigration, Refugees and Citizenship Canada
                                </li>
                            </ul>
                            Book a consultation with us and determine your eligibility!
                        </p>
                    </div>
                </div>
            </div>
            {/* Tabs */}
            {/* Tabs */}
            {/* Tabs */}
            <div className="page-tabs work2">
                <div className='main'>
                    <h2 className='heading'>LMIA WORK PERMIT</h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        There are two categories of work permits in Canada. The first category requires a Labour Market Impact Assessment (LMIA) or “labour market test”. The second category does not require to obtain such document (LMIA-exempt).
                    </p>
                    <div className="tab-header">
                        {tabs2.map((tab, index) => (
                            <div
                                key={index}
                                className={`tab-item ${activeTab === index ? 'active' : ''}`}
                                onClick={() => handleTabClick(index)}
                            >
                                <h2>{tab.label}</h2>
                            </div>
                        ))}
                    </div>
                    <div className="tab-content">
                        <h2>{tabs2[activeTab].heading}</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        {tabs2[activeTab].content}
                    </div>
                    <p>
                        If you find a job in Canada, your Canadian employer is only responsible for sharing the LMIA and a detailed job offer letter, which you will need to submit with your work permit application.
                    </p>
                </div>

            </div>
        </>
    )
}
