import React, { useState } from 'react'
import '../Css/Pages.css';
export default function ProvicialNomineeProgram() {

    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    const tabs = [
        {
            label: 'Tourism and Hospitality Stream ',
            heading: ' Tourism and Hospitality Stream',
            content:
                <p>
                    Eligible candidates residing and working in Alberta with a full-time job offer from an Alberta-based employer in the tourism and hospitality sector can apply for an AAIP nomination.
                </p>,
        },
        {
            label: 'Alberta Opportunity Stream ',
            heading: ' Alberta Opportunity Stream',
            content:
                <p>
                    Alberta Opportunity Stream	Eligible candidates residing and working in Alberta with a job offer from an Alberta employer are eligible to apply for an AAIP nomination. This stream aims to create opportunities for workers and is not limited to a specific industry or field.
                </p>
        },
        {
            label: 'Alberta Express Entry Stream',
            heading: 'Alberta Express Entry Stream',
            content:
                <p>
                    Qualified candidates may receive invitations to apply for nomination if their profiles in the federal Express Entry pool meet the minimum requirements and align with provincial economic priorities. This stream also offers specific pathways for healthcare professionals and technology workers.
                </p>
        },
        {
            label: 'Rural Renewal Stream',
            heading: ' Rural Renewal Stream',
            content:
                <p>
                    Rural Renewal Stream	Rural communities must obtain designation before endorsing qualified candidates. Temporary foreign workers with job offers from Alberta employers in those designated communities, along with an endorsement letter, are eligible to apply for an AAIP nomination.
                </p>
        },
    ];

    const tabs2 = [
        {
            label: 'Rural Entrepreneur Stream ',
            heading: 'Rural Entrepreneur Stream ',
            content:
                <p>
                    Entrepreneurs interested in starting or purchasing a business in a rural Alberta community can submit an Expression of Interest
                </p>,
        },
        {
            label: 'Graduate Entrepreneur Stream ',
            heading: ' Graduate Entrepreneur Stream',
            content:
                <p>
                    International graduates from approved Alberta post-secondary institutions seeking to establish or acquire a business can submit an Expression of Interest
                </p>
        },
        {
            label: 'Farm Stream',
            heading: 'Farm Stream',
            content:
                <p>
                    Experienced farmers planning to purchase or initiate a farm in Alberta are eligible to apply for nomination
                </p>
        },
        {
            label: 'Foreign Graduate Entrepreneur Stream',
            heading: ' Foreign Graduate Entrepreneur Stream',
            content:
                <p>
                    Foreign graduates holding degrees from non-Canadian post-secondary institutions aiming to launch a start-up enterprise can collaborate with a designated agency to submit an Expression of Interest
                </p>
        },
    ];

    const tabs3 = [
        {
            label: 'Skilled Worker ',
            heading: 'Skilled Worker ',
            content:
                <p>
                    This stream targets experienced workers in various occupations, aiming to secure permanent residence in British Columbia, and offers an Express Entry option.
                </p>,
        },
        {
            label: 'Health Authority ',
            heading: ' Health Authority',
            content:
                <p>
                    This stream provides a pathway to permanent residence for individuals working for a health authority in British Columbia, and also offers an Express Entry option.
                </p>
        },
        {
            label: 'Entry Level and Semi-Skilled',
            heading: 'Entry Level and Semi-Skilled',
            content:
                <p>
                    This stream is designed for workers in select occupations in the tourism, hospitality or food processing sectors to obtain permanent residence in British Columbia, but does not include an Express Entry option.
                </p>
        },
        {
            label: 'International Graduate',
            heading: ' International Graduate',
            content:
                <p>
                    This stream targets recent graduates with a degree or diploma from an eligible Canadian post-secondary institution, and offers an Express Entry option.
                </p>
        },
    ];
    return (


        <>
            <div className="page-hero provicial">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a> - provicial Nominee Program</h2>
                                <h1>PROVINCIAL NOMINEE PROGRAM</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The Provincial Nominee Program (PNP) is designed for individuals who possess the necessary skills, education, and work experience to enhance the economy while catering to the specific needs of a Canadian province or territory. The candidate must be open to residing in the particular province or territory linked to the Provincial Nominee Program, which will allow them to secure permanent residency while remaining in that specific province or territory.
                                <br />
                                <br />
                                Each province and territory establishes its own distinct "streams" or immigration programs tailored to specific groups. For instance, these programs may target students, business professionals, skilled workers, or semi-skilled workers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Category */}
            {/* Category */}
            {/* Category */}

            <div className="page-category provicial">
                <div className="main">
                    <div className="list">
                        <div className="item" >
                            <div className="image">
                                <div className="img">

                                </div>

                            </div>
                            <div className="dis">
                                <p>
                                    <strong>ALBERTA ADVANTAGE IMMIGRATION PROGRAM (AAIP)</strong>   The Alberta Advantage Immigration Program (AAIP) is an economic immigration initiative that selects individuals for permanent residency in Alberta. Candidates must possess skills to address job shortages or intend to establish or purchase a business in Alberta, and demonstrate the ability to support their families.
                                </p>
                            </div>
                        </div>
                        <div className="item" >
                            <div className="image">
                                <div className="img">

                                </div>

                            </div>
                            <div className="dis">
                                <p>
                                    <strong>BRITISH COLUMBIA NOMINEE PROGRAM (BC PNP)</strong> The BC Provincial Nominee Program (BC PNP) is an economic immigration initiative that enables the Province to choose economic immigrants to reside in British Columbia and contribute to filling job vacancies or establishing businesses.
                                </p>
                            </div>
                        </div>
                        <div className="item" >
                            <div className="image">
                                <div className="img">

                                </div>

                            </div>
                            <div className="dis">
                                <p>

                                    <strong>ONTARIO IMMIGRANT NOMINEE PROGRAM (OINP)</strong> The OINP serves as the province's economic immigration initiative, operating in collaboration with the Government of Canada. Individuals including foreign workers, international students, and others possessing suitable skills, experience, and education, can seek nomination through the OINP

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Start Normal */}
            {/* Start Normal */}
            {/* Start Normal */}
            <div className="page-start">
                <div className="main">
                    <h2>OUR SERVICES</h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        The PNP Program covers eleven (11) provinces and territories: Alberta, British Columbia, Manitoba, New Brunswick, Newfoundland and Labrador, Northwest Territories, Nova Scotia, Ontario, Prince Edward Island, Saskatchewan, and Yukon. The territory of Nunavut and the province of Quebec do not participate in provincial nominee programs.
                        <br />
                        <br />
                        The number of available "stream" programs varies by province and territory. At M&C Premier Immigration, our legal team strategically reviews each "stream" program to determine your eligibility. With a thorough understanding of the details and requirements of each "stream" program, our immigration lawyers can increase your chances of obtaining permanent residency by selecting the most suitable programs for you.
                    </p>
                </div>
            </div>



            {/* Our Services */}
            {/* Our Services */}
            {/* Our Services */}
            <div className="page-servies right  provicial">
                <div className="content">
                    <div className="images">

                    </div>
                    <div className="dis">
                        <h2>OUR SERVICES</h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>

                        <p>
                            When it comes to the step-by-step process, M&C Premier Immigration’s approach differs depending on whether you meet the criteria for one of the three Express Entry programs. For the non-Express Entry route :

                            <ul>
                                <li>
                                    <strong>STEP 1 – Province or Territory Selection:  </strong>Our immigration lawyers help you select the PNP that best matches your preferences for living in Canada and suits your personal profile, whether you’re a student, worker or an entrepreneur. They guide you through the application process for nomination under the selected province or territory's non-Express Entry streams.
                                </li>
                                <li>
                                    <strong>STEP 2 – Eligibility Check: </strong>Our legal team assesses your eligibility for the PNP in your chosen province or territory. If you're eligible for your chosen province or territory's "stream" program, we move on to STEP 3. Otherwise, we advise you on selecting another PNP that may also suit your preferences.
                                </li>
                                <li>
                                    <strong>STEP 3 – Nomination: </strong> After identifying the most suitable "stream" program for you, M&C Premier Immigration contacts the province or territory and submits the application for nomination on your behalf. The province or territory then reviews your application, considering its immigration needs, your compliance with the non-Express Entry stream requirements, and your genuine intention to reside there.
                                </li>
                                <li>
                                    <strong>STEP 4 – Apply for Permanent Residence:   </strong> :  If you receive a nomination from the province or territory of your choice, M&C Premier Immigration proceeds to manage the application process for your permanent residence. We handle all the paperwork and ensure that all necessary documents are uploaded accurately to prevent any risks of rejection. Typically, applications with all required documents undergo processing within twelve (12) months.
                                </li>

                            </ul>
                            You’ll find below a general overview of the some of the Provincial Nominee Programs.
                        </p>
                    </div>
                </div>
            </div>


            {/* Tabs Cross */}
            {/* Tabs Cross */}
            {/* Tabs Cross */}
            <div className="page-cross provicial">
                <div className='main'>
                    <h2 className='heading'>
                        ALBERTA ADVANTAGE IMMIGRATION PROGRAM (AAIP)
                    </h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        The program is a collaborative effort between the governments of Alberta and Canada. Successful nominees, along with their spouse or common-law partner, and dependent children, are eligible to apply for permanent residency.
                    </p>
                    <p>
                        <strong>
                            PROGRAM STREAM FOR WORKERS
                        </strong>
                    </p>
                    <div className="tab-main">
                        <div className="tab-header">
                            {tabs.map((tab, index) => (
                                <div
                                    key={index}
                                    className={`tab-item ${activeTab === index ? 'active' : ''}`}
                                    onClick={() => handleTabClick(index)}
                                >
                                    <h2>{tab.label}</h2>
                                </div>
                            ))}
                        </div>
                        <div className="tab-content">
                            <h2>{tabs[activeTab].heading}</h2>
                            <div className="border-out">
                                <div className="border">
                                </div>
                            </div>
                            {tabs[activeTab].content}
                        </div>

                    </div>
                </div>

            </div>


            {/* Tabs Cross 2*/}
            {/* Tabs Cross 2*/}
            {/* Tabs Cross 2*/}
            <div className="page-cross provicial2">
                <div className='main'>

                    <p>
                        <strong>
                            PROGRAM STREAM FOR ENTREPRENEUR
                        </strong>
                    </p>
                    <div className="tab-main">
                        <div className="tab-header">
                            {tabs2.map((tab, index) => (
                                <div
                                    key={index}
                                    className={`tab-item ${activeTab === index ? 'active' : ''}`}
                                    onClick={() => handleTabClick(index)}
                                >
                                    <h2>{tab.label}</h2>
                                </div>
                            ))}
                        </div>
                        <div className="tab-content">
                            <h2>{tabs2[activeTab].heading}</h2>
                            <div className="border-out">
                                <div className="border">
                                </div>
                            </div>
                            {tabs2[activeTab].content}
                        </div>

                    </div>
                </div>

            </div>


            {/* Start Normal */}
            {/* Start Normal */}
            {/* Start Normal */}
            <div className="page-start">
                <div className="main">
                    <h2>BRITISH COLUMBIA NOMINEE PROGRAM</h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        The BC Provincial Nominee Program (BC PNP) is an economic immigration program that allows the Province to select economic immigrants to settle in British Columbia and help fill job vacancies or establish business ventures. If nominated, you and your family can apply to Immigration, Refugees, and Citizenship Canada for permanent residence in Canada.
                        <br />
                        <br />
                        The BC PNP comprises of Skills Immigration, designed for workers and recent graduates possessing the skills, experience, and qualifications sought by employers in British Columbia, and Entrepreneur Immigration, tailored for experienced entrepreneurs aiming to establish and actively manage a business within the province.
                    </p>

                </div>
            </div>

            {/* Tabs Cross */}
            {/* Tabs Cross */}
            {/* Tabs Cross */}
            <div className="page-cross provicial3">
                <div className='main'>
                    <h2 className='heading'>
                        IMMIGRATION STREAM
                    </h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        Skills Immigration consists of five (5) streams, each with its own specific criteria. Eligibility for many of these streams is based on the National Occupational Classification (NOC) system –  the version of 2021.
                    </p>
                    <div className="tab-main">
                        <div className="tab-header">
                            {tabs3.map((tab, index) => (
                                <div
                                    key={index}
                                    className={`tab-item ${activeTab === index ? 'active' : ''}`}
                                    onClick={() => handleTabClick(index)}
                                >
                                    <h2>{tab.label}</h2>
                                </div>
                            ))}
                        </div>
                        <div className="tab-content">
                            <h2>{tabs3[activeTab].heading}</h2>
                            <div className="border-out">
                                <div className="border">
                                </div>
                            </div>
                            {tabs3[activeTab].content}
                        </div>

                    </div>
                </div>

            </div>

            {/* Start Normal */}
            {/* Start Normal */}
            {/* Start Normal */}
            <div className="page-start">
                <div className="main">
                    <h2>ENTREPRENEUR IMMIGRATION</h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        The British Columbia Provincial Nominee Program (BC PNP) Entrepreneur Immigration (EI) offers a route for international entrepreneurs aiming to settle in British Columbia, and establish businesses contributing to innovation and economic development in the province. Entrepreneur Immigration operates as a "temporary to permanent" immigration pathway, allowing successful applicants to initially arrive in British Columbia as temporary residents before transitioning to permanent status once their business ventures are underway.
                        <br />
                        <br />

                        The Base Stream is designed for experienced entrepreneurs aiming to launch new ventures or acquire and expand existing businesses in B.C.
                        <br />
                        <br />
                        The Strategic Projects targets foreign corporations with strategic investment opportunities in British Columbia who aim to establish operations in the province. This stream facilitates the permanent transfer of five (5) members of senior staff possessing corporate knowledge and expertise to British Columbia to actively manage operations.

                    </p>

                </div>
            </div>

            {/* Tabs */}
            {/* Tabs */}
            {/* Tabs */}
            <div className="page-tabs study">
                <div className='main'>
                    <h2 className='heading'>TWO-STEP PROCESS</h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <div className="tab-header">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`tab-item ${activeTab === index ? 'active' : ''}`}
                                onClick={() => handleTabClick(index)}
                            >
                                <h2>{tab.label}</h2>
                            </div>
                        ))}
                    </div>
                    <div className="tab-content">
                        <h2>{tabs[activeTab].heading}</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        {tabs[activeTab].content}
                    </div>
                </div>

            </div>




        </>
    )
}
