import React from 'react'
import '../Css/Pages.css';
export default function AtlanticImmigrationProgram() {
    return (
        <>
            <div className="page-hero study">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a> - Atlantic Immigration program</h2>
                                <h1>ATLANTIC IMMIGRATION PROGRAM</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The Atlantic Immigration Program offers a route to permanent residency for skilled foreign workers and graduates from Canadian institutions who wish to reside and work in any of Canada's four Atlantic provinces : New Brunswick, Nova Scotia, Prince Edward Island, or Newfoundland and Labrador. This initiative assists employers in recruiting suitable candidates for positions they have struggled to fill locally.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="part study">
                <div className="content">
                    <div className="sub">
                        <h2>ELIGIBILITY CRITERIA</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                            To be eligible for the program, you need to secure a job offer from an approved employer in Atlantic Canada. This program targets recent graduates from a recognized post-secondary institution in Atlantic Canada or skilled workers. Whether you currently reside abroad or are already in Canada as a temporary resident, you can apply.
                        </p>
                        <p>
                            To qualify for the Atlantic Immigration Program, you must:
                            <ul>
                                <li>
                                    Have relevant work experience, unless you're an international graduate from a recognized post-secondary institution in Atlantic Canada.
                                </li>
                                <li>
                                    Meet or surpass the educational criteria.
                                </li>
                                <li>
                                    Meet or surpass the language proficiency standards.
                                </li>
                                <li>
                                    Demonstrate sufficient funds to support yourself and your family upon arrival in Canada – if you're already residing and working in Canada with a valid work permit, you're exempt from providing proof.
                                </li>
                            </ul>
                        </p>
                        <p>
                            A candidate who meets these requirements can begin seeking employment with a designated employer in Atlantic Canada.
                        </p>
                    </div>
                </div>
            </div>
            <div className="part left study-left">
                <div className="content">
                    <div className="sub">
                        <h2>JOB OFFER</h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>
                        <p>
                            You'll need to explore job opportunities with specific employers designated by each province, including New Brunswick, Newfoundland and Labrador, Nova Scotia, and Prince Edward Island. <strong>The job offer must meet the following criteria:</strong>
                            <ul>
                                <li>
                                    It should be for full-time employment.
                                </li>
                                <li>
                                    It must be non-seasonal, indicating consistent and regular paid work throughout the year.
                                </li>
                                <li>
                                    For job offers falling under NOC 2021 categories of TEER 0, 1, 2, or 3, the employer must offer a position lasting at least one (1) year from your acquisition of permanent residency.
                                </li>
                                <li>
                                    For job offers categorized under NOC 2021 TEER 4, it must entail permanent employment without a predetermined end date.
                                </li>
                                <li>
                                    The job offer cannot originate from a company where you, the applicant, or your spouse/common-law partner are majority owners.
                                </li>
                                <li>
                                    The job must align with, or exceed, the skill level of the work experience that qualified you for the job, unless you're an international graduate from an accredited post-secondary institution in Atlantic Canada.
                                </li>
                            </ul>
                        </p>
                        <p>
                            Certain healthcare sector positions may not require a job offer at the same or higher skill level as the qualifying work experience : experience in NOC 32101 (licensed practical nurses) and NOC 31301 (registered nurses) can qualify if candidates receive an offer in NOC 33102 (nurse aides, orderlies, and patient service associates) or in NOC 44101 (home health care workers).
                            <br /><br />
                            When a designated employer extends a job offer, they will provide you with an "Offer of Employment to a Foreign National" form.

                        </p>

                    </div>
                </div>
            </div>

            {/* Our Services */}
            {/* Our Services */}
            {/* Our Services */}
            <div className="page-servies right study">
                <div className="content">
                    <div className="images">

                    </div>
                    <div className="dis">
                        <h2>OUR SERVICES  </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>

                        <p>
                        The process of verifying your eligibility and building an application is detail-oriented, and can be time-consuming. To ensure accuracy in evaluating your profile and to prevent any delays, extra expenses, or application rejection, it's advisable to hire immigration lawyers to handle your application preparation, maximizing your chances of success.
                        <br /><br />
                        Our services include the following: 
                        </p>
                        <ul>
                            <li>
                                <strong>ELIGIBILITY</strong>Initially, the team at M&C Premier Immigration will assess about your eligibility for the AIP by asking you a series of specific questions.
                                </li>
                            <li>
                                <strong>JOB OFFER </strong>If you meet the eligibility criteria, our lawyers will assist you in reaching out to employers to secure a job offer that aligns with the program's requirements.
                                </li>
                            <li>
                                <strong>DOCUMENT PREPARATION</strong> Upon receiving a valid job offer, our legal team will gather the necessary documentation from you to apply for permanent residence in Atlantic Canada under the program. This includes preparing a settlement plan and obtaining a certificate of endorsement.
                                </li>
                            <li>
                                <strong>PERMANENT RESIDENCE  </strong> Our lawyers will proceed to complete the requisite forms required for your permanent residence application and submit the application on your behalf.
                                 </li>
                            <li>
                                <strong>WORK PERMIT (optional) </strong>If applicable, we will assist you in applying for a work permit while your permanent residence application is under review.
                                </li>
                                <li>
                                    <strong>POST-APPLICATION ASSISTANCE</strong>ASSISTANCE Following your application submission, M&C Premier Immigration will continue to support you, providing guidance on what to anticipate throughout the process, including biometrics and medical examinations.
                                </li>
                        </ul>
                   
                    </div>
                </div>
            </div>
        </>
    )
}
