import React, { useState, useEffect } from 'react';
import '../Css/Footer.css';
import { Fade } from 'react-awesome-reveal';

export default function Footer() {


  return (
    <>
      <div className="footer">
        <Fade direction='left' duration={3000} >
          <div className="img">
            <img src="../assets/footer/02.png" alt="" />
          </div>
        </Fade>
      </div>
      <div className="footer-bottom">
        <div className="main">
          <p>
            Copyright © 2024, All rights reserved Moorcroft & Chazbek Premier Immigration
          </p>
        </div>
      </div>
    </>
  );
}
