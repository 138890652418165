import React from 'react'
import '../Css/Pages.css';
export default function StratUpVisaPermanentResidence() {
    return (
        <>
            <div className="page-hero study">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a> - Strat-up visa permanent residance</h2>
                                <h1>START-UP VISA PERMANENT RESIDENCE</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The start-up visa program is designed for foreign entrepreneurs who seek to establish themselves in Canada with a business idea. The start-ups can span a variety of industries, including but not limited to healthcare, agriculture, consumer products, renewable energy, finance, education, transportation, manufacturing processes, real estate, technology, and IT.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="part study">
                <div className="content">
                    <div className="sub">
                        <h2>LETTER OF SUPPORT </h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                            Within this program, applicants are required to obtain support for their business venture from a designated organization, which will then issue them a Letter of Support (LOS). In this letter, the organization needs to mention that the applicant is an "essential member" of the start-up who requires to be in Canada to begin operations of the business.
                            <br /><br />
                            The mention of being an "essential member" is crucial for securing a work permit, because Immigration, Refugees and Citizenship Canada requires this assurance to confirm your genuine intention to come to Canada and work on your business while your permanent residency application is being reviewed. This letter is key in the application process as it will be used to apply for permanent residence and a temporary work permit.

                        </p>
                    </div>
                </div>
            </div>
            <h1>filling missing part</h1>
            <div className="part left study-left">
                <div className="content">
                    <div className="sub">
                        <h2>FUNDS</h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>
                        <p>
                            The applicant must have sufficient funds to settle in Canada (“personal funds”). At a minimum, the funds should range between $13,757 and $36,8706 CAD depending on the size of the applicant’s family even if the family members are not accompanying the applicant to Canada.
                            <br /><br />
                            Each member of the start-up should also have access to capital funds to start their venture (“investment fund”). This dual requirement aims to ensure that not only can applicants support themselves and their families upon arrival in Canada but also that they have the financial resources to contribute to the growth and development of their start-up endeavors.
                            <br /><br />
                            The applicant needs to guarantee that the funds will be accessible both at the time of applying for a permanent residence and when they obtain permanent residency. They must also be able to prove that they can legally access this money upon their arrival to Canada.

                        </p>
                        <strong>LANGUAGE REQUIREMENT</strong>
                        <br />
                        <p>
                            The Applicant needs to achieve Canadian Language Benchmark 5 in listening, reading, writing and speaking in English or French, to qualify for the Start-Up Visa program. The results need to be from a language test done by an approved agency, such as Canadian English Language Proficiency Index Program (CELPIP) or International English Language Testing System (IELTS).
                        </p>

                    </div>
                </div>
            </div>
            <div className="part study">
                <div className="content">
                    <div className="sub">
                        <h2>APPLYING FOR A WORK PERMIT </h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                            Once the applicant secures a Letter of Support and fulfills the financial and linguistic requirements, the application for permanent residence is submitted. The processing time for the permanent residency application can vary and be lengthy. Therefore, in the meantime, the applicant may want to consider applying for a work permit to begin operations of their start-up in Canada while their application undergoes processing.
                            <br /><br />
                            Note that if a letter of support was issued to an investor group, all essential members of the group must submit their permanent residence applications before any applicant can receive a work permit.
                            <br /><br />
                            Under the start-up visa program, an applicant’s accompanying family members (spouse and children under the age 22) can also apply for study permits or open work permits. Each family member will have to fill out a separate application, which will be submitted with the applicant’s work permit.
                        </p>
                    </div>
                </div>
            </div>
            {/* Our Services */}
            {/* Our Services */}
            {/* Our Services */}
            <div className="page-servies right study">
                <div className="content">
                    <div className="images">

                    </div>
                    <div className="dis">
                        <h2>OUR SERVICES  </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>

                        <p>
                            We guide you through
                        </p>
                        <ul>
                            <li>
                                <strong>Development of your start-up concept:  </strong>At M&C Premier Immigration, we collaborate closely with our clients to refine and develop their initial business idea into a viable and compelling start-up concept.
                                </li>
                            <li>
                                <strong>Drafting a business plan : </strong>Our legal professionals have assisted numerous start-ups in the conception of their venture. We specialize in helping our clients develop thorough business plans that detail their business goals, strategies for growth, market analysis, and operational structure.
                                </li>
                            <li>
                                <strong>Building a pitch deck : </strong> : We help our clients craft a complete and persuasive pitch deck that effectively communicates your start-up's potential, value and mission.
                                </li>
                            <li>
                                <strong>Financial projections : </strong> With the help of our experts, we develop detailed financial projections that forecast your start-up's revenue, expenses, and cash flow, providing a realistic outlook on its financial performance in Canadian markets.
                                </li>
                            <li>
                                <strong>Applying for a Letter of Support: </strong>: We guide you through the process of applying for a Letter of Support from a designated organization, which is a mandatory requirement for obtaining a start-up visa in Canada.
                                </li>
                                <li>
                                    <strong>Incorporation of your company in Canada: </strong>Our legal professionals guides you through the process of establishing your start-up in Canada, ensuring full adherence to Federal and Provincial regulations, and offering expert assistance at each stage of the incorporation process.
                                </li>
                                <li>
                                    <strong>Registration of patents or trademark (if required): </strong>We handle the registration process for patents or trademarks, safeguarding your start-up's intellectual property rights and enhancing its competitive advantage.
                                </li>
                                <li>
                                    <strong>Gathering the required documents for a work permit: </strong>We help you compile all necessary documents, such as educational credentials, employment history, and financial records, needed to apply for a work permit in Canada.
                                </li>
                                <li>
                                    <strong>Applying for permanent residence : </strong>Our lawyers will gather and complete the necessary documentation and submit your work permit application, ensuring accuracy and compliance with immigration regulations to facilitate your employment in Canada.
                                </li>
                        </ul>
                      
                    </div>
                </div>
            </div>
        </>
    )
}
