import React from 'react'
import '../Css/Pages.css';
export default function Misrepresentation() {
  return (
    <>
    <div className="page-hero study">
        <div className="main">
            <div className="inner">
                <div className="header">
                    <div className="border">
                    </div>
                    <div className="headings">
                        <h2><a href="/"><span>Home</span></a> - Misrepresentation</h2>
                        <h1>MISREPRESENTATION</h1>
                    </div>
                </div>
                <div className="introduce">
                    <p>
                        Canada is a top destination for international students from all over the world. From university to post-graduate studies, Canada is known for offering high quality education, internship opportunities and access to a wide network of professionals. Whether you decide to stay in Canada or return to your country of residence, your academic experience and acquired connections will propel your career. If you want to benefit from Canadian education, you will need a study permit.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div className="part study">
        <div className="content">
            <div className="sub">
                <h2>WHAT IS MISREPRESENTATION</h2>
                <div className="border-out">
                    <div className="border">
                    </div>
                </div>
                <p>
                The act of misrepresentation is a statutory notion under section 40 of the Immigration and Refugee Protection Act (“Act”), and is an offence as per Immigration, Refugees and Citizenship Canada. It may lead to an inadmissibility ban for a permanent resident, or a foreign national.
                </p>
                <p>
                Fraud and misrepresentation are done intentionally and can include, but is not limited to:
                <ul>
                    <li>
                    providing incorrect or false information while interacting with an officer
                    </li>
                    <li>
                    providing incorrect or false information in an immigration application  
                    </li>
                    <li>
                    deliberately holding back information which can make you inadmissible to Canada (even information which has not been asked directly) 
                    </li>
                    <li>
                    providing altered or falsified :
                    <ul>
                        <li>travel documents</li>
                        <li>educational documents, such as diplomas, degrees, transcripts, credential evaluations, apprenticeship documents</li>
                        <li>certificates of birth, marriage, separation, divorce or death</li>
                        <li>certificates of police clearance</li>
                    </ul>  
                    </li>
                </ul>  
                </p>
            </div>
        </div>
    </div>
    <div className="part left study-left">
        <div className="content">
            <div className="sub">
                <h2>CONSEQUENCES OF MISREPRESENTATION</h2>
                <div className="border-out">
                    <div className="border">

                    </div>
                </div>
                <p>
                When misrepresentation is found, it can result in significant financial loss for the Applicant, inclusion in Immigration, Refugees and Citizenship Canada’s fraud records, and lead to an inadmissibility ban from entering Canada that can last up to five (5) years.
 <br /><br />
 This inadmissibility ban will have consequences on the Applicant’s future applications for entry to Canada, but also affect the Applicant’s credibility in other travel plans to countries that are members of the Five Eyes Intelligence Alliance – Australia, Canada, New Zealand, the United Kingdom and the United States – and have an information-sharing program with Canada.
  <br /><br />
 Lastly, a person can be refused entry to Canada, have their legal status in Canada revoked or their application rejected if misrepresentation is found anywhere during the process.
 
                </p>

            </div>
        </div>
    </div>
    <div className="part study">
        <div className="content">
            <div className="sub">
                <h2>WHY HIRE A LAWYER?</h2>
                <div className="border-out">
                    <div className="border">
                    </div>
                </div>
                <p>
                The Federal Court’s case law regarding misrepresentation is very strict. Even if an applicant was assisted by a third party in their application, and did not know of the misrepresentation, they retain the ultimate responsibility and duty of due diligence to ensure that they meet their legal obligation for their application to be truthful as per section 16 of the Act :
 <br /><br />
 <em>16 (1) A person who makes an application must answer truthfully all questions put to them for the purpose of the examination and must produce a visa and all relevant evidence and documents that the officer reasonably requires.
 </em>
 <br /><br />
 However, exceptions to the general principle arises when there are truly exceptional circumstances where the applicant honestly and reasonably believed they were not misrepresenting as stipulated by section 40 of IRPA – that is, the “innocent mistake” exception.
  <br /><br />
 As per the Federal Court’s case law, the “innocent mistake” exception is applicable when the applicant (i) held honest subjective belief that they were not misrepresenting or withholding, (ii) that their belief was reasonable from an objective point of view and (iii) that they had a lack of control over the knowledge of misrepresentation.
  <br /><br />
 Consequently, hiring immigration lawyers is crucial, as they will take into account the strict jurisprudence regarding misrepresentation when drafting your Procedural Fairness Letter response and compiling documentation. It is to be repeated that for your defense, your legal counsel is only allowed to use the Procedural Fairness Letter response, the supporting documentation and your immigration application. 
 <br /><br />
 Far too often, our legal team has had to do damage control for applicants who drafted their own response or hired an immigration consultant to do so. Unfortunately, this has led our lawyers to refuse to take on cases, as the prospects of overturning the decisions were very low.
  
 </p>
            </div>
        </div>
    </div>
    {/* Our Services */}
    {/* Our Services */}
    {/* Our Services */}
    <div className="page-servies right study">
        <div className="content">
            <div className="images">

            </div>
            <div className="dis">
                <h2>OUR SERVICES  </h2>
                <div className="border-out">
                    <div className="border">

                    </div>
                </div>

                <p>
                If you are facing misrepresentation allegations, the legal team at M&C Premier Immigration will go over your application, the Procedural Fairness Letter issued by Immigration, Refugees and Citizenship Canada, and obtain your version of the story before deciding to take your file under their wing. 
<br /><br />
They will determine the prospects of crafting a strong defense and share with you the chances of a success based on their experience and knowledge of the law and Federal Court’s case law. Note that the fees may vary depending on the complexity of the allegations and the factual context of your application.

                </p>
                
                <p>
                Book a same-day consultation with our lawyers!
                </p>
                <p>
                Please mention the misrepresentation allegations in your booking. Such cases are treated as a priority, as we strive to accommodate same-day consultations due to the urgency of short delays and serious consequences associated with misrepresentation allegations.     
                </p>
            </div>
        </div>
    </div>
</>
  )
}
