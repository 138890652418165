import React from 'react';
import '../Css/Pages.css';

export default function VisiteCanada() {
    return (
        <>
            <div className="page-hero">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a>- Visit Canada</h2>
                                <h1>VISIT CANADA</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                Explore the vibrant streets of Toronto, discover the French culture of Montreal, or witness the northern lights in Yukon – the choice is yours. Whether you're planning a short-term holiday for tourism, visiting family and friends, exploring business opportunities, or expanding your network, a visitor visa may be necessary to enter Canada.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="part">
                <div className="content">
                    <div className="sub">

                        <h2>KEY TO A SUCCESSFUL APPLICATION </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>
                            <p>
                                Immigration, Refugees, and Citizenship Canada requires assurance that you'll leave Canada at the end of your authorized stay. As a foreign national, you must convince the visa officer that your intentions as a visitor are genuine. M&C Premier Immigration is here to help you build a persuasive application that satisfies this requirement.
                                <br />
                                <br />
                                From drafting essential letters, selecting the documents that best showcase your ties to your country of residence, and demonstrating the purpose of your visit to Canada, our team can assist you every step of the way.
                                <br />
                                <br />
                                With extensive experience before the Federal Court, our lawyers possess an in-depth understanding of the legal grounds for refusals, and they will strategically build your application around these crucial factors to increase its strength and likelihood of success.

                            </p>
                    </div>
                </div>
            </div>
            <div className="part left">
                <div className="content">
                    <div className="sub">
                        <h2>TYPES OF VISITOR VISA </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>
                        <p>
                            There are two types of Canadian visitor visas. <strong>single-entry visa</strong>  or <strong>multiple-entry visa</strong>
                            <br />
                            <br />
                            A <strong>single-entry</strong> visa grants the foreign national only one entry into Canada, while a multiple-entry visa allows for limitless travel between Canada and other countries until expiry of the document. Applicants are considered for a multiple-entry visa by default unless specific circumstances require a single-entry visa.
                            <br />
                            <br />
                            With a <strong>multiple-entry</strong> visa, individuals can stay in Canada for up to six (6) months per visit, offering greater flexibility for travel arrangements. The validity period of a visa can extend up to ten (10) years. The exact duration of the validity of your visa is determined by the issuing visa officer.

                        </p>
                    </div>
                </div>
            </div>
            {/* Our Services */}
            {/* Our Services */}
            {/* Our Services */}
            <div className="page-servies right">
                <div className="content">
                    <div className="images">

                    </div>
                    <div className="dis">
                        <h2>OUR SERVICES  </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>
                      
                            <p>
                                At M&C Premier Immigration, our lawyers will guide you through the presentation of your purpose of visit, financial documentation and ties to your country of residence. Our priority is to help you emphasize these aspects of your application in a manner that will  convince the officer that you will depart Canada at the end of your stay. This includes reviewing your invitation letter(s), and drafting submissions to the visa officer as your legal representatives.
                                <br />
                                <br />
                                Beyond legal representation, our lawyers will assist you in securing the most cost-effective travel accommodations if needed, and lend you a hand in organizing a schedule your their stay in Canada.
                                <br />
                                <br />
                                Book a consultation with us and share with us your visiting plans!


                            </p>
                    </div>
                </div>
            </div>
        </>
    )
}
