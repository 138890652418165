import React, { useRef } from 'react';
import '../Css/Mission.css';
import { motion, useScroll, useTransform } from 'framer-motion';

export default function Mission() {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
    });

    const x = useTransform(scrollYProgress, [0, 1], ["0%", "-70%"]);
    return (
        <section ref={targetRef} className="mission">
            <div className="mission-sub">
                <div className="bg-images">
                    <img src="../assets/lawyer/01.png" alt="" />
                </div>
                <motion.div style={{ x }} className="motion">
                    <div className="mission-content">

                        <div className="leaf2">
                            <img src="../assets/logo/leaf.png" alt="" />
                        </div>
                        <div className="our-mission">
                            <div className="leaf">
                                <img src="../assets/logo/leaf.png" alt="" />
                            </div>
                            <div className="main">
                                <h1>OUR MISSION</h1>
                                <div className="border-out">
                                    <div className="border">

                                    </div>
                                </div>
                                <p>At Moorcroft & Chazbek Premier Immigration, we understand the inherent challenges of the immigration process: confusion, high costs, and uncertainty. Our mission is to redefine this experience by making transparency and communication with our clientele a priority. As a boutique firm, we take pride in offering guidance in accordance with your specific needs and treating each case with personal attention. We strive to empower our clients with knowledge and view ourselves not just as immigration lawyers, but as trusted advisors ready to assist in both your immigration and business endeavors in Canada.</p>
                            </div>
                        </div>
                        <div className="why-hire">
                           
                            <div className="main">
                            <div className="leaf3">
                                <img src="../assets/logo/leaf.png" alt="" />
                            </div>
                                <h1>WHY HIRE A LAWYER? </h1>
                                <div className="border-out">
                                    <div className="border">

                                    </div>
                                </div>
                                <p>
                                    The preparation of an immigration application is more than filling out forms. Hiring an immigration lawyer can significantly improve your chances of a successful application and avoid undue costs and delays. Our ultimate goal is to secure approval on the first submission with our knowledge of immigration guidelines, legislation, and case law.
                                    <br />
                                    <br />
                                    We are often contacted for damage control once situations have escalated due to individuals representing themselves or claiming to be consultants without proper registration with the College of Immigration and Citizenship Consultants of Canada (CICC). Failure to address issues accurately from the outset can result in costly delays or adverse outcomes.
                                    <br />
                                    <br />
                                    If a refusal of your application arises, our lawyers are qualified to represent you before the authorities of Immigration, Refugees and Citizenship Canada, and appeal your decision to the Federal Court.
                                </p>
                            </div>
                        </div>
                        <div className="bg-logo-img">
                            <img src="../assets/logo/logo-color.png" alt="" />
                        </div>
                        <div className="bg-canada-img">
                            <img src="../assets/lawyer/canada.png" alt="" />
                        </div>
                        <div className="bottom-border">
                        </div>
                    </div>
                </motion.div>

            </div>
            {/* <div className="sticky top-0 flex h-screen items-center overflow-hidden">
               
            </div> */}
        </section>
    )
}
