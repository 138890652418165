import React, { useState } from 'react'
import '../Css/Pages.css';

export default function StartUpVisaProgram() {

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const tabs = [
        {
            label: 'VENTURE CAPITAL FUNDS  ',
            heading: ' VENTURE CAPITAL FUNDS ',
            content:
                <p>
                    The foreign entrepreneurs can get one these organizations to agree to invest a minimum of $200,000 in their start-up.
                </p>,
        },
        {
            label: 'ANGEL INVESTOR GROUPS  ',
            heading: 'ANGEL INVESTOR GROUPS ',
            content:
                <p>
                    The foreign entrepreneurs can get one or more investors connected to these groups to agree to invest a minimum of $75,000 in their start-up.
                </p>
        },
        {
            label: 'BUSINESS INCUBATORS   ',
            heading: 'BUSINESS INCUBATORS  ',
            content:
                <p>
                    The foreign entrepreneurs can be accepted into one of these programs.
                </p>
        },
    ];
    return (
        <>
            <div className="page-hero startup">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h2><a href="/"><span>Home</span></a> - START-UP VISA PROGRAM</h2>
                                <h1>START-UP VISA PROGRAM </h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The start-up visa program is designed for foreign entrepreneurs who seek to establish themselves in Canada with a business idea. The start-ups can span a variety of industries, including healthcare, agriculture, consumer products, renewable energy, finance, education, transportation, manufacturing processes, real estate, technology, and IT.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Normal */}
            {/* Start Normal */}
            {/* Start Normal */}
            <div className="page-start">
                <div className="main">
                    <h2>LETTER OF SUPPORT </h2>
                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        Within this program, applicants are required to obtain support for their business venture from a designated organization, which will then issue them a Letter of Support (LOS). In this letter, the organization needs to mention that the applicant is an essential member of the start-up and require to be in Canada to begin operations of the business. This letter is key in the application process, because it will be used to apply for permanent residence and a temporary work permit.
                    </p>
                </div>
            </div>


            {/* Tabs */}
            {/* Tabs */}
            {/* Tabs */}


            <div className="page-tabs startup">
                <div className='main'>
                    <h2 className='heading'>DESIGNATED ORGANIZATIONS </h2>

                    <div className="border-out">
                        <div className="border">
                        </div>
                    </div>
                    <p>
                        Immigration, Refugees and Citizenship Canada has designated a list of venture capital funds, angel investor groups, and business incubators to participate in the Start-Up Visa program. Successful applicants are required to secure a minimum investment or support for their Canadian start-up, depending on the type of organization.
                    </p>
                    <div className="tab-header">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`tab-item ${activeTab === index ? 'active' : ''}`}
                                onClick={() => handleTabClick(index)}
                            >
                                <h2>{tab.label}</h2>
                            </div>
                        ))}
                    </div>
                    <div className="tab-content">
                        <h2>{tabs[activeTab].heading}</h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        {tabs[activeTab].content}
                    </div>
                </div>
            </div>






            {/* part */}
            {/* part */}
            {/* part */}

            <div className="part startup">
                <div className="content">
                    <div className="sub">
                        <h2>FUNDS</h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>
                        <p>
                            The applicant must have sufficient funds to settle in Canada (“personal funds”), which, at a minimum, should range between $27,514 and $72,814 depending on the size of their family. Each member of the start-up should also have access to capital funds to start their venture (“investment fund”).
                            <br /><br />
                            This dual requirement aims to ensure that not only can applicants support themselves and their families upon arrival in Canada but also that they have the financial resources to contribute to the growth and development of their start-up endeavors.

                        </p>

                    </div>
                </div>
            </div>
            <div className="part left startup">
                <div className="content">
                    <div className="sub">
                        <h2>APPLYING FOR A WORK PERMIT </h2>
                        <div className="border-out">
                            <div className="border">
                            </div>
                        </div>
                        <p>
                            Once applicants secure a Letter of Support and fulfill financial and linguistic requirements, the application for permanent residence is submitted. After applying for permanent residency, applicants can apply for a work permit to begin operations of their start-up in Canada while their application undergoes processing. Note that if a letter of support was given to an investor group, all essential members of the group must submit their permanent residence applications before any applicant can get a work permit.
                            <br /><br />
                            Under the start-up visa program, an applicant’s accompanying family members (spouse and children under age 22) can also apply for study permits or open work permits. Each family member will have to fill out a separate application, which will be submitted with the applicant’s work permit.

                        </p>
                    </div>
                </div>
            </div>
            {/* Our Services */}
            {/* Our Services */}
            {/* Our Services */}
            <div className="page-servies right startup">
                <div className="content">
                    <div className="images">

                    </div>
                    <div className="dis">
                        <h2>OUR SERVICES  </h2>
                        <div className="border-out">
                            <div className="border">

                            </div>
                        </div>

                        <p>
                            We guide you through
                            <ul>
                                <li>
                                    <strong>Development of your start-up concept: </strong>At M&C Premier Immigration, we collaborate closely with our clients to refine and develop their initial business idea into a viable and compelling start-up concept.
                                </li>
                                <li>
                                    <strong>Drafting a business plan :  </strong>Our legal professionals have assisted numerous start-ups in the conception of their venture. We specialize in helping our clients develop thorough business plans that detail their business goals, strategies for growth, market analysis, and operational structure.
                                </li>
                                <li>
                                    <strong>Building a pitch deck : </strong> We help our clients craft a complete and persuasive pitch deck that effectively communicates your start-up's potential, value and mission.
                                </li>
                                <li>
                                    <strong>Financial projections: </strong> With the help of our experts, we develop detailed financial projections that forecast your start-up's revenue, expenses, and cash flow, providing a realistic outlook on its financial performance in Canadian markets.
                                </li>
                                <li>
                                    <strong>Applying for a Letter of Support:  </strong>We guide you through the process of applying for a Letter of Support from a designated organization, which is a mandatory requirement for obtaining a start-up visa in Canada.
                                </li>
                                <li><strong>Incorporation of your company in Canada: </strong>Our legal professionals guides you through the process of establishing your start-up in Canada, ensuring full adherence to Federal and Provincial regulations, and offering expert assistance at each stage of the incorporation process.
                                </li>
                                <li>
                                    <strong>Registration of patents or trademark (if required): </strong> We handle the registration process for patents or trademarks, safeguarding your start-up's intellectual property rights and enhancing its competitive advantage.
                                </li>
                                <li>
                                    <strong>Gathering the required documents for a work permit: </strong>We help you compile all necessary documents, such as educational credentials, employment history, and financial records, needed to apply for a work permit in Canada.
                                </li>
                                <li>
                                    <strong>Applying for a work permit: </strong>Our lawyers will gather and complete the necessary documentation and submit your work permit application, ensuring accuracy and compliance with immigration regulations to facilitate your employment in Canada.
                                </li>
                            </ul>
                          
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
