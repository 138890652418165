import React from 'react';
import '../Css/Expert.css';

export default function Expert() {
    return (
        <div className="expert">
            <div className="bg-images">
                <img src="../assets/expert/01.jpg" alt="" />
            </div>
            <div className="contetnt">
                <div className="main">
                    <div className="image">
                        <img src="../assets/expert/01.jpg" alt="" />

                    </div>
                    <div className="details">
                        <h1>OUR VALUES</h1>
                        <div className="border-out">
                            <div className="border">
                                <div className="main">

                                </div>
                                <div className="sub">

                                </div>
                            </div>
                        </div>
                        <div className="list">
                            <div className="item">
                                <h3>
                                    EXCELLENCE
                                </h3>
                                <p>
                                    We strive for excellence in every aspect of our work with precision, attention to detail, and continuous updating of legal knowledge to maintain high standards.
                                </p>
                            </div>
                            <div className="item">
                                <h3>
                                    INTEGRITY
                                </h3>
                                <p>
                                    We don’t sugar-coat! We prioritize transparency, and honesty and effective communication in all our interactions with clients, partners, and stakeholders.
                                </p>
                            </div>
                            <div className="item">
                                <h3>
                                    CLIENT-CENTRIC APPROACH
                                </h3>
                                <p>
                                    Our clients are at the heart of our work. We cater our services to their unique circumstances, aiming to build a strong network of long-lasting connections.
                                </p>
                            </div>
                            <div className="item">
                                <h3>
                                    EMPOWERMENT
                                </h3>
                                <p>
                                    We empower our clients with knowledge and resources and enable them to make informed decisions that shape their future.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
{/* <img src="../assets/expert/01.jpg" alt="" /> */ }